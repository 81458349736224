import React from 'react'
import { useLocation } from 'react-router'
import moment from 'moment';

function Tracking() {
    const location = useLocation()
    console.log("Loc", location);
    return (
        <div className="container">
            {location.state.trackingStatus === "" ?
                <>
                    <h5>Order Status: {location.state.currentStatus}</h5>
                    <div className="row my-3">
                        <div className="col-12 col-md-10 hh-grayBox pt45 pb20">
                            <div className="row justify-content-between">
                                {location.state?.allOrderStatus.map((item, index) => {
                                    return (
                                        <div key={index} className="order-tracking completed">
                                            <span className="is-complete"></span>
                                            <p>{item.activity}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </> :
                <h4 style={{marginBottom: "50px"}}>{location.state.trackingStatus}</h4>
            }

        </div>
    )
}

export default Tracking