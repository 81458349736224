import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../src/Layout/Header";
import Footer from "../src/Layout/Footer";
import Home from "../src/view/Home/Home";
import Shopdetails from "./view/Shop/Shopdetails";
import Wishlist from "../src/view/Wishlist/Wishlist";
import Cart from "../src/view/Cart/Cart";
import Shop from "./view/Shop/index";
import About from "./view/About/index";
import Contact from '../src/view/Contact/Index'
import Account from "./view/Account";
import Login from "../src/view/Authentic/Login";
import Register from "../src/view/Authentic/Register";
import HomepageLayout from "./Layout/HomepageLayout";
import Review from "./Component/ReviewForm/Review";
import Pagination from "./Component/Pagination/Pagination";
import Checkoutpage from "../src/view/CheckoutPage/index";
import { ToastContainer } from "react-toastify";
import TermsCondition from "../src/view/TermsOfService/Index";
import Privacy from "../src/view/PrivacyPolicy/Index";
import ReturnPolicy from './view/ReturnPolicy'
import Faq from "../src/view/Faq/Index";
import ProductByWishlist from "./view/TopWhislist";
import Tracking from "./view/Tracking";

function App() {
  return (
    <React.Fragment>
      <Router>
        {/* <ToastContainer /> */}
        <Routes>
          {/* <Route path="/pagination" element={<Pagination />} />
          <Route path="/review" element={<Review />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} /> */}
          <Route element={<HomepageLayout />}>
            <Route path="/" exact element={<Home />} />
            <Route path="/shopdetails/:id" element={<Shopdetails />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/Shop/:id/:cat" element={<Shop />} />
            <Route path="/Shopbycat/:catId" element={<Shop />} />
            <Route path="/shoptype/:id" element={<Shop />} />
            <Route path="/shopprice/:priceId" element={<Shop />} />
            <Route path="/shopage/:sub/:age" element={<Shop />} />
            <Route path="/about" element={<About />} />
            <Route path="/account" element={<Account />} />
            <Route path="/checkout-page" element={<Checkoutpage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms-condition" element={<TermsCondition />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/returnPolicy" element={<ReturnPolicy />} />
            <Route path="/topWishlist" element={<ProductByWishlist />} />
            <Route path="/track" element={<Tracking />} />
            <Route path="/faq" element={<Faq />} />
          </Route>
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
