import { createSlice } from '@reduxjs/toolkit'

export const cartSlice = createSlice({
  name: 'user',
  initialState: {
    cartData: [],
    buyNowDAta: []
  },
  reducers: {
    removeAllItem(state){
      state.cartData =[]
    },
    initCart(state, action){
      state.cartData = action.payload;
    },
    setCartData(state, action) {
      state.cartData = [...state.cartData, action.payload]
    },
    setBuyNow(state, action){
      state.buyNowDAta = action.payload
    },
    removeItem(state, action) {
      let id = action.payload;
      let index = state.cartData.findIndex(it => it.id == id)

      if(index >= 0){
        state.cartData.splice(index, 1)
      }
      
    }
  }
})
export const { setCartData, removeItem, initCart,removeAllItem, setBuyNow } = cartSlice.actions;

export default cartSlice.reducer;