import React, { useState } from "react";
import HttpClient from "../utils/HttpClient"
import { toast } from "react-toastify";

function Popup(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const onChangePhoneNumber = (val) => {
    var pattern = new RegExp(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
    );

    if (!pattern.test(val)) {
      setError(true);
      setPhone(val);
    } else if (phone.length !== 9) {
      setError(true);
      setPhone(val);
    } else {
      setError(false);
      setPhone(val);
    }
  };

  const submitContact = async () => {
    if (name !== "" && email !== "" && phone !== "" && message !== "" && address !== "") {
      let emailRegex = /^([\-\.0-9a-zA-Z]+)@([\-\.0-9a-zA-Z]+)\.([a-zA-Z]){1,7}$/;
      if (emailRegex.test(email)) {
        let dataSend = {
          name: name,
          email: email,
          phone: phone,
          address: address,
          message: message,
        };
        console.log("Contact", dataSend);
        let res = await HttpClient.requestData(
          "frontend/addVendorContact",
          "POST",
          dataSend
        );
        console.log("GetContact", res);
        if (res && res.status) {
          toast("Contact Sent Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(function () {
            props.setTrigger(false);
          }, 2000);
        } else {
          toast("Contact already exists! Try a new one...", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
      else {
        toast.error('Enter a valid email!')
      }
    } else {
      toast("All Fields are Required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return props.trigger ? (
    <div className="partner_pop_up">
      <div className="partner_pop_up_body">
        <h4>Partner With Us</h4>
        <p onClick={() => props.setTrigger(false)} className="closeBtn">
          X
        </p>
        <div className="pop_up_content">
          <div className="form-group mb-2">
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group mb-2">
            <input
              type="email"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-group mb-2">
            <input
              type="tel"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Phone"
              onChange={(e) => onChangePhoneNumber(e.target.value)}
            />
            {error && (
              <p style={{ color: "red" }}>phone number must be valid</p>
            )}
          </div>
          <div className="form-group mb-2">
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Address"
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="form-group mb-2">
            <textarea
              className="form-control"
              placeholder="Message"
              id="exampleFormControlTextarea1"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <button onClick={submitContact} type="button" class="btn mt-2">
            Send
          </button>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default Popup;
