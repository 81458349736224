import React, { useEffect, useRef, useState } from 'react'

const FadeInanimation = ({ children, fadeUp = false }) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {
                threshold: 0.1, // Trigger when 10% of the element is visible
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [isVisible]);
    return (
        <div
            ref={ref}
            className={`${fadeUp ? 'fade-up' : 'fade-in'} ${isVisible ? (fadeUp ? 'fade-up-visible' : 'fade-in-visible') : ''}`}
        >
            {children}
        </div>
    )
}

export default FadeInanimation
