import { combineReducers } from "redux";
import User from './User';
import Loader from "./Loader"
import Cart from "./Cart";
import LoginShow from "./LoginShow";
export default combineReducers({
   User,
   Loader,
   Cart,
   LoginShow
  
})