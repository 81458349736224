import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Popular from "../../Component/Popular";
import xx from "../../images/1984.jpg";
import da from "../../images/investing-book-img.jpg";
import ia from "../../images/icon-15555.svg";
import ib from "../../images/icon-15556.svg";
import ic from "../../images/icon-15557.svg";
import id from "../../images/icon-15558.svg";
import ie from "../../images/icon-15559.svg";
import book from "../../images/book.jpeg";
import h from "../../images/1984.jpg";
import { useParams } from "react-router";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { useDispatch } from "react-redux";
import { setCartData, setBuyNow } from "../../Redux/reducer/Cart";
import { useNavigate } from "react-router";
import { setShow } from "../../Redux/reducer/LoginShow";
import { HideLoad, setLoad } from "../../Redux/reducer/Loader";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactStars from "react-rating-stars-component";
import { img_url } from "../../utils/HttpClient";
import { useLocation } from "react-router";
import Stationery from "../../images/Stationery.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Autoplay } from 'swiper/modules';
export default function ProductDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  let id = params.id;
  // console.log(id);
  const [productDetail, setProductDetail] = useState([]);
  const [uploadImage, setUploadImage] = useState("");
  const [additionalImg, setadditionalImg] = useState([]);
  const [popularProduct, setPopularProduct] = useState([]);
  const [wishStatus, setWishStatus] = useState(null)
  const [wishId, setWishId] = useState("")

  const location = useLocation()
  useEffect(() => {
    // alert("kk")
    window.scrollTo(0, 0)
    // 👇️ scroll to top on page load
    // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [location.key]);

  useEffect(() => {
    fetchAllProductByProId();
    fatchRelatedProduct();
  }, [id]);

  const fetchAllProductByProId = async () => {
    let user = reactLocalStorage.getObject("userData");
    dispatch(setLoad());
    let dataSend = {
      product_id: id,
      user_id: user ? user?.id : ""
    };
    let res = await HttpClient.requestData(
      "frontend/fetchProductDetails",
      "POST",
      dataSend
    );
    console.log("single", res);
    if (res && res.status) {
      setProductDetail(res.data[0]);
      setWishStatus(res.data[0]?.pro_wish_status)
      setWishId(res.data[0]?.pro_wish_id?.id)
      // console.log("Productdetails", res.data);
      setadditionalImg(res.data[0].additional_image);
    }
    dispatch(HideLoad());
  };

  const fatchRelatedProduct = async () => {
    let data = {
      product_id: id,
    };
    const result = await HttpClient.requestData(
      "frontend/fetchOtherProduct",
      "POST",
      data
    );
    if (result && result.status) {
      // console.log("FetchProd", result.data);
      // let similarProd = result.data.filter(item => item.product_type_details.producttype === productDetail.product_type)
      // console.log("SimProd", similarProd);
      setPopularProduct(result.data);
    } else {
      // console.log("Popular Product Not fatch");
    }
  };

  // var settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: false,
  //       },
  //     },
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: false,
  //       },
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  const slideData = [1, 2, 3, 4, 5, 6];
  const loop = [1, 2, 3, 4, 5];

  const AddWishList = async () => {
    let user = reactLocalStorage.getObject("userData");
    if (Object.keys(user).length === 0) {
      toast.error("Please login first to add wishlist!");
    }
    else {
      console.log("User", user);
      let data = {
        user_id: user.id,
        product_id: parseInt(id)
      }
      const result = await HttpClient.requestData('user/userWishlist', 'POST', data)
      if (result && result.status) {
        setWishStatus(result.status_data)
        setWishId(result?.data?.id)
        console.log("wish", result);
      }
    }
  }

  const removeWishList = async () => {
    const data = {
      wish_id: wishId
    }
    const result = await HttpClient.requestData('user/userWishlist_remove', 'POST', data)
    console.log("rmvWish", result);
    setWishStatus(result?.status_data)
  }

  const AddCart = async (p_id) => {
    let login = reactLocalStorage.get("login_status");
    let user = reactLocalStorage.getObject("userData");
    if (login) {
      let dataSend = {
        user_id: user.id,
        product_id: p_id,
        product_count: 1,
      };
      // console.log("data", dataSend);
      let result = await HttpClient.requestData(
        "user/product_add_to_cart",
        "POST",
        dataSend
      );
      // console.log(result);
      if (result && result.status) {
        // console.log("result.data", result.data);
        dispatch(setCartData(result.data));
        // alert("Product added in cart!!!");
        toast.success("Product added to cart!!!");
        // navigate("/cart");
      } else {
        // console.log("error", result)
        // alert(result.error);
        toast.warn(result.error);
      }
    } else {
      // alert("Please Login first")
      dispatch(setShow());
    }
  };
  const BuyNow = async (p_id) => {
    let login = reactLocalStorage.get("login_status");
    let user = reactLocalStorage.getObject("userData");
    if (login !== undefined) {
      let dataSend = {
        user_id: user.id,
        product_id: p_id,
        product_count: 1,
      };
      // console.log("data", dataSend);
      let result = await HttpClient.requestData(
        "user/product_add_to_cart",
        "POST",
        dataSend
      );
      // console.log(result);
      if (result && result.status) {
        // console.log("result.data", result.data);
        dispatch(setCartData(result.data));
        // alert("Product added in cart!!!");
        // toast.success("Product added in cart!!!");
        navigate("/checkout-page");
      } else {
        // console.log("error", result)
        // alert(result.error);
        toast.warn(result.error);
      }
    } else {
      // alert("Please Login first")
      dispatch(setShow());
    }
  };


  useEffect(() => {
    function magnify(imgID, zoom) {
      var img, glass, w, h, bw;
      img = document.getElementById(imgID);
      /*create magnifier glass:*/
      glass = document.createElement("DIV");
      glass.setAttribute("class", "img-magnifier-glass");
      /*insert magnifier glass:*/
      img.parentElement.insertBefore(glass, img);
      /*set background properties for the magnifier glass:*/
      glass.style.backgroundImage = "url('" + img.src + "')";
      glass.style.backgroundRepeat = "no-repeat";
      glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
      bw = 3;
      w = glass.offsetWidth / 2;
      h = glass.offsetHeight / 2;
      /*execute a function when someone moves the magnifier glass over the image:*/
      glass.addEventListener("mousemove", moveMagnifier);
      img.addEventListener("mousemove", moveMagnifier);
      glass.addEventListener("mouseout", () => {
        glass.style.display = "none";
      })

      /*and also for touch screens:*/
      glass.addEventListener("touchmove", moveMagnifier);
      img.addEventListener("touchmove", moveMagnifier);
      function moveMagnifier(e) {

        var pos, x, y;
        /*prevent any other actions that may occur when moving over the image*/
        e.preventDefault();
        /*get the cursor's x and y positions:*/
        pos = getCursorPos(e);
        x = pos.x;
        y = pos.y;
        console.log(x, y, "position");
        /*prevent the magnifier glass from being positioned outside the image:*/
        if (x > img.width - (w / zoom)) { x = img.width - (w / zoom); }
        if (x < w / zoom) { x = w / zoom; }
        if (y > img.height - (h / zoom)) { y = img.height - (h / zoom); }
        if (y < h / zoom) { y = h / zoom; }
        /*set the position of the magnifier glass:*/
        // glass.style.left = (x) + "px";
        // glass.style.top = (y) + "px";
        /*display what the magnifier glass "sees":*/
        glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
        glass.style.display = "block";

      }
      function getCursorPos(e) {
        var a, x = 0, y = 0;
        e = e || window.event;
        /*get the x and y positions of the image:*/
        a = img.getBoundingClientRect();
        /*calculate the cursor's x and y coordinates, relative to the image:*/
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        /*consider any page scrolling:*/
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return { x: x, y: y };
      }
    }

    magnify("myimage", 4)

  }, [uploadImage])
  // console.log("here...", productDetail.additional_image);
  return (
    <>
      <ToastContainer />
      <div>
        <section className=" clearfix">
          <div className="container-fluid">
            <div className="hoemshpedetailsbgimsggh" style={{
              backgroundImage: `url('${Stationery}')`
            }}>
              <div className="shop-list-totsldib">
                <ul className="shop-list-totsldibulmaion">
                  <li className="shop-list-totsldibulmaionli">
                    <Link to="/" className="shop-list-totsldibulmaionliaa">Home / </Link>
                  </li>

                  <li className="shop-list-totsldibulmaionli">
                    <Link to="/shopprice/499" className="shop-list-totsldibulmaionliaa">Shop / </Link>
                  </li>


                  <li className="shop-list-totsldibulmaionli">
                    <Link to="/" className="shop-list-totsldibulmaionliaatxtpp">Shopdetails </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="shopdetailsdivmaindd">
                  <div className="dyfdytfytft">

                    <div className="dyfdytfytftdivfrst">
                      {/* <Swiper
                        slidesPerView={4}
                        spaceBetween={30}
                        loop={true}
                        navigation={true}
                      
                        modules={[Autoplay, Navigation]}
                        className="myOwnSwiper"
                      >
                        {additionalImg.length > 0 &&
                          additionalImg.map((val, idx) => {
                            return (
                              <SwiperSlide>
                                <div
                                  key={idx}
                                  className=" producushpedetaimsimgmain my-2"
                                  onClick={() => {
                                    setUploadImage(val.image);

                                  }}
                                >
                                  <div className="producushpedetaimsimg">
                                    <img
                                      className="img-fluid"
                                      src={`${img_url}/${val.image}`}
                                    />
                                  </div>

                                </div>
                              </SwiperSlide>
                            );
                          })}
                      </Swiper> */}
                      {additionalImg.length > 0 &&
                        additionalImg.map((val, idx) => {
                          return (
                            <div
                              key={idx}
                              className=" producushpedetaimsimgmain my-2"
                              onClick={() => {
                                setUploadImage(val.image);

                              }}
                            >
                              <div className="producushpedetaimsimg">
                                <img
                                  className="img-fluid"
                                  src={`${img_url}/${val.image}`}
                                />
                              </div>

                            </div>
                          )
                        })}

                    </div>
                    <div className="dyfdytfytftdivscnd">
                      <div className="bookwali-shop-product-top-img">
                        {
                          uploadImage !== "" ? (
                            <div class="img-magnifier-container gdfgdfgeetimg">
                              <img id="myimage" src={`${img_url}/${uploadImage}`} style={{ cursor: "zoom-in" }} />
                            </div>                            // <ReactImageMagnify
                            //   src={`${img_url}/${uploadImage}`}
                            //   width={350}
                            // />
                          ) : (
                            <div class="img-magnifier-container gdfgdfgeetimg">
                              <img id="myimage" src={`${img_url}/${productDetail.main_img}`} />
                            </div>

                            // <ReactImageMagnify
                            //   src={`${img_url}/${productDetail.main_img}`}
                            //   width={350}
                            // />
                          )
                        }
                        {/* <span className="zoom-icon">
                          <i className="fa fa-search" aria-hidden="true" />
                        </span> */
                        }
                        {wishStatus ?
                          <div className="whislist_area" style={{ position: "absolute", top: "10px", right: "10px" }}>
                            <button onClick={removeWishList} className="btn active" style={{ width: "30px", height: "35px", textDecoration: "none", boxShadow: "none", padding: "5px", display: "flex", alignItems: "center", justifyContent: "center", }}><i className="fa fa-heart-o" aria-hidden="true"></i></button>
                          </div> :
                          <div className="whislist_area" style={{ position: "absolute", top: "10px", right: "10px" }}>
                            <button onClick={AddWishList} className="btn" style={{ width: "30px", height: "35px", textDecoration: "none", boxShadow: "none", padding: "5px", display: "flex", alignItems: "center", justifyContent: "center", }}><i className="fa fa-heart-o" aria-hidden="true"></i></button>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="dyfdytfytftdivthrd">
                      <div className="bookwali-shop-product-top-content">
                        {productDetail?.product_stock?.stock ? (
                          <span className="stock-status out-stock">
                            {" "}
                            Sale on{" "}
                          </span>
                        ) : (
                          <span className="stock-status out-stock">
                            {" "}
                            Out of Stock{" "}
                          </span>
                        )}

                        <h2 className="title-detail">
                          {productDetail.Product_title}
                        </h2>
                        <div className="product-rate-cover text-end gygygygy">
                          <div className="product-rate d-inline">
                            <ul style={{ cursor: "auto" }} className="product-rate-list">
                              <li className="float-left">
                                <a style={{ cursor: "auto" }} href="#">
                                  <i style={{ color: "#fbb009" }} className="fa fa-star" aria-hidden="true" />
                                </a>
                              </li>
                              <li className="float-left">
                                <a style={{ cursor: "auto" }} href="#">
                                  <i style={{ color: "#fbb009" }} className="fa fa-star" aria-hidden="true" />
                                </a>
                              </li>
                              <li className="float-left">
                                <a style={{ cursor: "auto" }} href="#">
                                  <i style={{ color: "#fbb009" }} className="fa fa-star" aria-hidden="true" />
                                </a>
                              </li>
                              <li className="float-left">
                                <a style={{ cursor: "auto" }} href="#">
                                  <i style={{ color: "#fbb009" }} className="fa fa-star" aria-hidden="true" />
                                </a>
                              </li>
                              <li className="float-left">
                                <a style={{ cursor: "auto" }} href="#">
                                  <i style={{ color: "#fbb009" }} className="fa fa-star" aria-hidden="true" />
                                </a>
                              </li>
                              <li className="float-left">
                                <a style={{ cursor: "auto" }} href="#">
                                  <i
                                    style={{ color: "#fbb009" }}
                                    className="fa fa-star-half-o"
                                    aria-hidden="true"
                                  />
                                </a>
                              </li>
                            </ul>
                            {/* <ul className="product-rating">
                              <ReactStars
                                // count={5}
                                value={productDetail.product_rating}
                                // onChange={ratingChanged}
                                size={25}
                                activeColor="#ffd700"
                              />
                            </ul> */}
                          </div>
                          <span className="font-small ml-3 text-muted">
                            {" "}
                            ({productDetail.count_of_rating} reviews)
                          </span>
                        </div>
                        <div className="clearfix product-price-cover">
                          <span className="save-price font-md color3 ml-15 fgfgsgfgcsg">
                            {Math.floor(
                              (productDetail.discount /
                                productDetail.price) *
                              100
                            )}
                            % off
                          </span>
                          <div
                            className="product-price primary-color float-left shopdetailsdivfllvvdxh"
                            style={{ color: "#fdc040" }}
                          >
                            <span className="current-price text-brand">
                              ₹{productDetail.selling_price}
                            </span>



                            {/* <span className="save-price font-md color3 ml-15">
                              {Math.floor(
                                (productDetail.discount /
                                  productDetail.price) *
                                100
                              )}
                              % off
                            </span> */}
                            <span className="old-price font-md ml-15">
                              ₹{productDetail.price}
                            </span>





                          </div>
                        </div>
                        <div className="short-desc">
                          <p className="font-lg">{productDetail.short_des}</p>
                        </div>
                        {/* <div className="list-filter-wrap d-flex mb-30">
                        <h4 className="font-size:16px; margin-right:20px; margin-top:5px;">
                          <strong>Size / Weight: </strong>
                        </h4>
                        <ul className="list-filter font-small">
                          <li className="float-left">
                            <a href="#">50g</a>
                          </li>
                          <li className="active float-left">
                            <a href="#">60g</a>
                          </li>
                          <li className="float-left">
                            <a href="#">80g</a>
                          </li>
                          <li className="float-left">
                            <a href="#">100g</a>
                          </li>
                          <li className="float-left">
                            <a href="#">150g</a>
                          </li>
                        </ul>
                      </div> */}
                        {productDetail?.product_stock?.stock != null ? (
                          <div className="shopBtn">
                            <button
                              onClick={() => {
                                BuyNow(id);
                              }}
                              type="button"
                              className="btn btnShop"
                            >
                              Buy Now
                            </button>
                            <button
                              type="button"
                              className="btn addcart"
                              onClick={() => AddCart(id)}
                            >
                              Add To Cart
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="shoprelatedproductdetails">

                    {popularProduct.length > 0 && (

                      <>
                        <h2 className="reattetstxttppp">Related Products</h2>

                        <div className="guysagtyudasas">
                          <Swiper
                            slidesPerView={5}
                            spaceBetween={10}
                            loop={true}
                            pagination={{
                              clickable: true,
                            }}
                            // navigation={true}
                            // modules={[Navigation]}
                            breakpoints={{
                              480: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                              },
                              768: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                              },
                              991: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                              },
                              1200: {
                                slidesPerView: 4,
                                spaceBetween: 10,
                              },

                              1366: {
                                slidesPerView: 5,
                                spaceBetween: 10,
                              },
                            }}
                            className="myHotDealsSwiper"
                          >
                            {popularProduct.map((item, index) => {
                              return (
                                <SwiperSlide>
                                  <div className="bookwali-product-box" key={index}>
                                    <div className="bookwali-productbg">
                                      <div className="bookwali-product-img">
                                        {/* <div className="bookwali-product-badges">
                                                <span className="hot">Hot</span>
                                              </div> */}
                                        <Link to={`/shopdetails/${item.product_id}`}>
                                          <img
                                            src={`${img_url}/${item.additional_image[0].image}`}

                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="bookwali-product-content">
                                      <h4>
                                        <a href="#">{item.Category_name}</a>
                                      </h4>
                                      <h2>
                                        <a href="#">{item.Product_title}</a>
                                      </h2>
                                      <div className="product-rate-box">
                                        <ul className="product-rate-list">
                                          <li className="float-left">
                                            <a href="#">
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </a>
                                          </li>
                                          <li className="float-left">
                                            <a href="#">
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </a>
                                          </li>
                                          <li className="float-left">
                                            <a href="#">
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </a>
                                          </li>
                                          <li className="float-left">
                                            <a href="#">
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </a>
                                          </li>
                                          <li className="float-left">
                                            <a href="#">
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </a>
                                          </li>
                                          <li className="float-left">
                                            <a href="#">
                                              <i
                                                className="fa fa-star-half-o"
                                                aria-hidden="true"
                                              />
                                            </a>
                                          </li>
                                        </ul>
                                        <span
                                          className="font-small"
                                          style={{ color: "#B6B6B6", fontSize: 12 }}
                                        >
                                          (4.0)
                                        </span>
                                      </div>
                                      <div
                                        className="font-small jhgjgusa"
                                        style={{ color: "#B6B6B6", fontSize: 12 }}
                                      >
                                        By<span className="ugyujguytgxs">{item.publishers}</span>
                                      </div>
                                      <div className="product-card-bottom">
                                        <div className="product-price">
                                          <span>₹ {item.selling_price}</span>
                                          <span className="old-price">
                                            ₹{item.price}
                                          </span>
                                        </div>
                                        <div className="product-add-cart">
                                          <a
                                            className="product-add-btn"
                                            onClick={() => AddCart(item.product_id)}
                                          >
                                            <i
                                              className="fa fa-shopping-cart"
                                              aria-hidden="true"
                                            />
                                            Add{" "}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div>

                      </>


                    )}

                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 primary-sidebar ">
                <div className="theiaStickySidebar">
                  <div className="sidebar-widget widget-category-2 mb-30">
                    <h5 className="section-title style-1 mb-30">Category</h5>
                    <ul>
                      <li>
                        <a href="shop-grid-right.html">
                          {" "}
                          <img src={ia} alt="" />
                          Study
                        </a>
                        <span className="count">30</span>
                      </li>
                      <li>
                        <a href="shop-grid-right.html">
                          {" "}
                          <img src={ib} alt="" />
                          Play
                        </a>
                        <span className="count">35</span>
                      </li>
                      <li>
                        <a href="shop-grid-right.html">
                          {" "}
                          <img src={ic} alt="" />
                          Read{" "}
                        </a>
                        <span className="count">42</span>
                      </li>
                      <li>
                        <a href="shop-grid-right.html">
                          {" "}
                          <img src={id} alt="" />
                          Celebrate
                        </a>
                        <span className="count">22</span>
                      </li>
                      <li>
                        <a href="shop-grid-right.html">
                          {" "}
                          <img src={ie} alt="" />
                          Decorate
                        </a>
                        <span className="count">4</span>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="sidebar-widget price_range range mb-30"
                    style={{ display: "none" }}
                  >
                    <h5 className="section-title style-1 mb-30">
                      Fill by price
                    </h5>
                    <div className="list-group">
                      <div className="list-group-item mb-10 mt-10">
                        <label className="fw-900">Color</label>
                        <div className="custome-checkbox">
                          <input
                            className="checbox__Form"
                            type="checkbox"
                            name="checkbox"
                            id="exampleCheckbox1"
                            defaultValue=""
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheckbox1"
                          >
                            <span>Red (56)</span>
                          </label>
                          <br />
                          <input
                            className="checbox__Form"
                            type="checkbox"
                            name="checkbox"
                            id="exampleCheckbox2"
                            defaultValue=""
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheckbox2"
                          >
                            <span>Green (78)</span>
                          </label>
                          <br />
                          <input
                            className="checbox__Form"
                            type="checkbox"
                            name="checkbox"
                            id="exampleCheckbox3"
                            defaultValue=""
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheckbox3"
                          >
                            <span>Blue (54)</span>
                          </label>
                        </div>
                        <label className="fw-900 mt-15">Item Condition</label>
                        <div className="custome-checkbox">
                          <input
                            className="checbox__Form"
                            type="checkbox"
                            name="checkbox"
                            id="exampleCheckbox11"
                            defaultValue=""
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheckbox11"
                          >
                            <span>New (1506)</span>
                          </label>
                          <br />
                          <input
                            className="checbox__Form"
                            type="checkbox"
                            name="checkbox"
                            id="exampleCheckbox21"
                            defaultValue=""
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheckbox21"
                          >
                            <span>Refurbished (27)</span>
                          </label>
                          <br />
                          <input
                            className="checbox__Form"
                            type="checkbox"
                            name="checkbox"
                            id="exampleCheckbox31"
                            defaultValue=""
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheckbox31"
                          >
                            <span>Used (45)</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <a href="#" className="btn btn-sm filter__Btn btn-default">
                      <i className="fa-solid fa-filter ml-2" /> Fillter
                    </a>
                  </div>
                  <div
                    style={{ display: "none" }}
                    className="sidebar-widget product-sidebar mb-30 p-30 bg-grey border-radius-10"
                  >
                    <h5 className="section-title style-1 mb-30">
                      New products
                    </h5>
                    <div className="single-post clearfix">
                      <div className="image">
                        <img src={book} alt="#" className="img-fluid" />
                      </div>
                      <div className="content pt-10">
                        <h5>
                          <a href="#">Chen Cardigan</a>
                        </h5>
                        <p className="price mb-0 ">₹99.50</p>
                        <div className="product-rate">
                          <div className="product-rating">
                            <i className="fa-solid fa-star" />
                            <i className="fa-solid fa-star" />
                            <i className="fa-solid fa-star" />
                            <i className="fa-solid fa-star-half-stroke" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="single-post clearfix">
                      <div className="image">
                        <img src={book} alt="#" className="img-fluid" />
                      </div>
                      <div className="content pt-10">
                        <h5>
                          <a href="#">Chen Sweater</a>
                        </h5>
                        <p className="price mb-0 ">₹89.50</p>
                        <div className="product-rate">
                          <div className="product-rating">
                            <i className="fa-solid fa-star" />
                            <i className="fa-solid fa-star" />
                            <i className="fa-solid fa-star" />
                            <i className="fa-solid fa-star-half-stroke" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="single-post clearfix">
                      <div className="image">
                        <img src={book} alt="#" className="img-fluid" />
                      </div>
                      <div className="content pt-10">
                        <h5>
                          <a href="#">Colorful Jacket</a>
                        </h5>
                        <p className="price mb-0 ">₹25</p>
                        <div className="product-rate">
                          <div className="product-rating">
                            <i className="fa-solid fa-star" />
                            <i className="fa-solid fa-star" />
                            <i className="fa-solid fa-star" />
                            <i className="fa-solid fa-star-half-stroke" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="banner-img wow fadeIn mb-lg-0 animated d-lg-block d-none"
                    style={{ visibility: "hidden", animationName: "none" }}
                  >
                    <img src="assets/imgs/banner/banner-11.png" alt="" />
                    <div className="banner-text">
                      <span>Oganic</span>
                      <h4>
                        Save 17% <br />
                        on <span className="text-brand">Oganic</span>
                        <br />
                        Juice
                      </h4>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>


          </div>
        </section>
      </div>
    </>
  );
}
