import React, { Fragment } from 'react'
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Outlet } from 'react-router';
import Loginregister from '../view/Authentic/index';
import { useSelector } from 'react-redux';
import Loader from '../Component/Loader/Loader';

export default function HomepageLayout() {
  const LoginShow = useSelector(state=>state.LoginShow.show)
  const {load} = useSelector(state=>state.Loader)
  return (
<Fragment>
 {LoginShow && <Loginregister/>}
<Header/>
<Outlet/>
<Footer/>
{load &&<Loader/>}
</Fragment>
  )
}
