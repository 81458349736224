import React, { useState } from "react";
import "./Loginregister.css";
import a from "../../images/logobookswali.png";
import HttpClient from "../../utils/HttpClient";
import { useDispatch } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { setuser } from "../../Redux/reducer/User";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { HideShow } from "../../Redux/reducer/LoginShow";
import { toast } from "react-toastify";
import { requestShiprocket } from "../../utils/HttpClient";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const authRocket = async () => {
    let data = {
      email: "Bookswali.com@gmail.com",
      password: "Book@123"
    }
    const result = await requestShiprocket('auth/login', 'POST', data)
    console.log("Rocket", result);
    reactLocalStorage.set('token', result.token)
  }

  const submitHandle = async (e) => {
    e.preventDefault();
    if (email !== "" && password !== "") {
      let dataSend = {
        email,
        password,
      };
      let result = await HttpClient.requestData(
        "user/userlogin",
        "POST",
        dataSend
      );
      if (result && result.status) {
        dispatch(setuser(result.data));
        reactLocalStorage.set("login_status", true);
        reactLocalStorage.setObject("userData", result.data);
        toast("User Signed In Successfully ", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // navigate("/");
        dispatch(HideShow())
        authRocket()
      } else {
        toast("Invalid Credentials...", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast("All field required...", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <div className="form-container sign-in-container">
        <form action="#" className="mobile-form">
          <h1>Sign in</h1>
          <span>or use your account</span>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(val) => setEmail(val.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(val) => setPassword(val.target.value)}
          />
          <a href="#">Forgot your password?</a>
          <button onClick={submitHandle}>Sign In</button>
        </form>
      </div>
    </>
  );
}
