import React, { useState } from "react";
import { Link } from "react-router-dom";
import a from "../../src/images/Booklogo.png";
import d from "../images/app-store.jpg";
import e from "../images/google-play.jpg";
import f from "../images/payment-method.png";
import g from "../images/phone-call.png";
import Popup from "../Component/Popup";
import Newsletter from "../images/book-142109 (1).jpg";

export default function Footer() {
  const [buttonpopup, setButtonpopup] = useState("");
  return (
    <div>
      <footer className="book____footer_part clearfix">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="newsletterbox row">
                {/* <div className="col-lg-4 col-md-4 col-12">
                  <div className="img_area">
                    <img src={Newsletter} className="img-fluid" alt="image" />
                  </div>
                </div> */}
                <div className="col-12 text">
                  <h2>Newsletter</h2>
                  <p>Get up to date information on exclusive products, offers and discounts. You can unsubscribe any time.</p>
                </div>
              </div>

            </div>
          </div>
          <div className="row fftyuguguguyiuiuhik">
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="widget-about">
                <div className="footer__logo mb-30">
                  <a href="#" className="mb-15 bkjbhbsbsvhhbb">
                    <img src={a} alt="logo" />
                  </a>
                  <p className="footer_text-heading">
                    Free Shipping Over Rs. 250
                  </p>
                  <p className="footer_text-heading">
                    Cash on Delivery Available
                  </p>
                  <p className="footer_text-heading">
                    Buy Now, Pay Later Available
                  </p>
                  <p className="footer_text-heading">Easy Returns</p>
                  <p className="footer_text-heading">Shipping All Over India</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="footer-link-widget">
                <h4 className="widget-title2">Follow Us</h4>
                <div className="mobile-social-icon">
                  <a href="https://www.facebook.com/profile.php?id=100087267468007" target="_blank">
                    <i className="fa-brands fa-facebook-f" />
                  </a>
                  <a href="https://twitter.com/BookswaliCom" target="_blank">
                    <i className="fa-brands fa-twitter" />
                  </a>
                  <a href="https://www.instagram.com/bookswali.com22/" target="_blank">
                    <i className="fa-brands fa-instagram" />
                  </a>
                  <a href="https://www.youtube.com/channel/UC98v9NwNZDNAq7hQc3TC85w" target="_blank">
                    <i className="fa fa-youtube-play" style={{ color: "#fff" }} />
                  </a>
                </div>
              </div>
              <div className="footer-link-widget">
                <p className="footer_text-heading" style={{ marginTop: '10px' }}>Contact No: +91-981-555-2881</p>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="footer-link-widget">
                <h4 className="widget-title2">Information</h4>
                <ul className="footer-list mb-md-0">
                  <li>
                    <Link to="/about">About us</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact us</Link>
                  </li>
                  {/* <li>
                    <Link to="/faq">FAQs</Link>
                  </li> */}
                  <li>
                    <Link to="/terms-condition">Terms and Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/returnPolicy">Return and Refund Policy</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="footer-link-widget">
                <h4 className="widget-title2">Write to us</h4>
                <p className="footer_text-heading">
                  We are just an e-mail away. For any query or suggestion, write
                  to us at
                </p>
                <p>
                  <a href="mailto:care@bookswali.com" title="care@bookswali.com" target="_blank">
                    care@bookswali.com
                  </a>
                </p>
                <p>
                  <a href="#" onClick={() => setButtonpopup(true)}>Want to partner with us?</a>
                </p>
                <p>
                  Address: Plot 58-59, Village Kotla, Hoshiarpur Road, Jalandhar
                  City; 144025
                </p>
              </div>
            </div>
          </div>

          <hr />
          <div className="bottom__footer_part">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                <p className="font-sm mb-0">
                  © 2022,{" "}
                  <strong className="text-brand" style={{ marginRight: "5px" }}>
                    Bookswali
                  </strong>
                  All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <Popup trigger={buttonpopup} setTrigger={setButtonpopup} />
    </div>
  );
}
