import React, { Fragment } from "react";
import Login from "./Login";
import Register from "./Register";
import a from "../../images/logobookswali.png";
import { useDispatch } from "react-redux";
import { HideShow } from "../../Redux/reducer/LoginShow";


function Index() {
    const dispatch =useDispatch()
  const openSignupPagge = () => {
    const bookswaliregisterlogin = document.getElementById(
      "customBookswalicontainer"
    );

    bookswaliregisterlogin.classList.add("right-panel-active");
  };
  const openSignInPage = () => {
    const bookswaliregisterlogin = document.getElementById(
      "customBookswalicontainer"
    );
    bookswaliregisterlogin.classList.remove("right-panel-active");
  };
const hideLoginPage = ()=>{
    dispatch(HideShow())
}
  return (
    <Fragment>
    <div className="fixedForm">
    <div className="hideLogin" onClick={hideLoginPage}/>

      <div className="bookswaliregisterlogin" id="customBookswalicontainer">
        <Register />
        <Login />
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <a href="#">
                <img src={a} className="img-fluid" alt="Logo" />
              </a>
              <h1>Welcome Back!</h1>
              <p>
                To keep connected with us please login with your personal info
              </p>
              <button className="ghost" id="signIn" onClick={openSignInPage}>
                Sign In
              </button>
            </div>
            <div className="overlay-panel overlay-right">
              <a href="#">
                <img src={a} className="img-fluid" alt="Logo" />
              </a>
              <h1>Hello, Friend!</h1>
              <p>Enter your personal details and start journey with us</p>
              <button className="ghost" id="signUp" onClick={openSignupPagge}>
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Fragment>
  );
}

export default Index;
