import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import prflcircle from "../../images/prflcircle.jpeg";
import HttpClient from "../../utils/HttpClient";
import Star from "../../Component/Star/RatingStar";
import { reactLocalStorage } from "reactjs-localstorage";
import { PROFILE_IMG } from "../../utils/HttpClient";
import { Autoplay } from "swiper/modules";
import Ellipse_7 from "../../images/Ellipse_7.png";
import Ellipse_12 from "../../images/Ellipse_12.png";
import comment_person_1 from "../../images/comment_person_1.png";
import landFootPic2 from "../../images/landFootPic2.png";
import profilephoto2 from "../../images/profilephoto2.png";
import user_image from "../../images/user_image.png"
export default function Testimonial() {
  useEffect(() => {
    fetchAllReview();
  }, []);

  const [allReview, setAllReview] = useState([]);

  let userData = reactLocalStorage.getObject("userData");

  const fetchAllReview = async () => {
    let res = await HttpClient.requestData("frontend/fetch_review", "POST");
    if (res && res.status) {
      console.log("AllReview...", res.data);
      setAllReview(res.data);
    }
  };

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const allReviewdata = [
    {
      image: Ellipse_7,
      name: "Mrs. Upoma Dey",
      rating:"5",
      comment: "Bookswali is a solid option for online shopping, particularly for those who value variety and competitive pricing. "
    },
    {
      image: Ellipse_12,
      name: "Mr Rupom Sarkar",
      rating:"5",
      comment: "I like this app , this app in useful for book shopping easy to shopping and buy the product easy to return and products quality is good I am also use this app for shopping"
    },
    {
      image: comment_person_1,
      name: "Sudhirdisha",
      rating:"4.5",
      comment: "Bookswali is the best online shopping store. It has the best customer service. If the product received is damage or something it gets replades within 5 days. The website is very user friendly and easy for the customers to search for the products."
    },
    {
      image: landFootPic2,
      name: "Ritu Das",
      rating:"4",
      comment: "My experience is really good for Bookswali . I am regular customer of Bookswali . I have using this app since last 4 year ."
    },
    {
      image: profilephoto2,
      name: "Mr Ujjal Chatterjee",
      rating:"5",
      comment: "I got Good delivery experience and Giving Good Customer service . Ordering any Goods from the App Simple Presentation while compare to Remaining apps"
    },
    {
      image: user_image,
      name: "saumitra",
      rating:"5",
      comment: "Easy to communicate with customer care. Even they take return back which are non returnable items if its logical. Since long years I am customer of Flipkart"
    },
    {
      image: comment_person_1,
      name: "Sudhirdisha",
      rating:"4.5",
      comment: "Bookswali is the best online shopping store. It has the best customer service. If the product received is damage or something it gets replades within 5 days. The website is very user friendly and easy for the customers to search for the products."
    },
    {
      image: Ellipse_7,
      name: "Mrs. Upoma Dey",
      rating:"5",
      comment: "Bookswali is a solid option for online shopping, particularly for those who value variety and competitive pricing. "
    },
  ];

  return (
    <>
      <section className="Testimonial">
        <div className="container-fluid">

          <div className="sectionhead">
            <h4>Testimonials</h4>
          </div>

          <div className="">
            {/* <Slider {...settings}>
              {allReview.map((item, index) => {
                return (
                  <div className="reviewprofileimagebgimgaeborder" key={index}>
                    <div className="reviewprofileimagebgimgae">
                      <div className="reviewprofileimage">
                        <img src={userData.user_image ? `${PROFILE_IMG}${userData.user_image}` : prflcircle} />
                        <img src={Ellipse_7} alt="..."/>
                      </div>
                      <div>
                        <div className="reveiwtetxtnamepppstartdisplayflex">
                        <h3 className="reveiwtetxtnameppp">{item?.user_name?.name}</h3>
                          
                        </div>
                        <Star stars={item.rating} />
                        <p className="tsttxtdivhh">{item.commnd}</p>
                      </div>
                    </div>


                  

                  </div>
                );
              })}
            </Slider> */}
            <Slider {...settings}>
              {allReviewdata.map((item, index) => {
                return (
                  <div className="reviewprofileimagebgimgaeborder" key={index}>
                    <div className="reviewprofileimagebgimgae">
                      <div className="reviewprofileimage">
                        <img src={item.image} />
                       
                      </div>
                      <div>
                        <div className="reveiwtetxtnamepppstartdisplayflex">
                          <h3 className="reveiwtetxtnameppp">{item?.name}</h3>

                        </div>
                        <Star stars={item.rating} />
                        <p className="tsttxtdivhh">{item.comment}</p>
                      </div>
                    </div>




                  </div>
                );
              })}
            </Slider>
          </div>

        </div>
      </section>
    </>
  );
}
