import React from 'react'
import "./Loader.css"


import bookImage from '../../images/book.gif';

function Loader() {
  return (
    // <div className='loadermain'>
    //   <div>
    //     <ul className='loaderul'>
    //       <li></li>
    //       <li></li>
    //       <li></li>
    //       <li></li>
    //       <li></li>
    //       <li></li>
    //       <li></li>
    //     </ul>
    //   </div>
    // </div>

    // <div className="spinner-wrapper">
    //   <div className="spinner">
    //     <ul>
    //       <li></li>
    //       <li></li>
    //       <li></li>
    //     </ul>
    //     <ul>
    //       <li></li>
    //       <li></li>
    //       <li></li>
    //     </ul>
    //   </div>
    //   <div className="gradient">
    //   </div>
    // </div>

    
      <div className='overlay_Loader_overlay'>
        <div className='overlay_Loader'>
          <img src={bookImage} />
        </div>
      </div>
  )
}

export default Loader