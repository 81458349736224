import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import a from "../../src/images/logobookswali.png";
import book from "../images/book.jpeg";
import ia from "../images/icon-15555.svg";
import ib from "../images/icon-15556.svg";
import ic from "../images/icon-15557.svg";
import id from "../images/icon-15558.svg";
import ie from "../images/icon-15559.svg";
import flag from "../../src/images/Flag_of_India.png";
import cart from "../../src/images/cart.png";
import HttpClient from "../utils/HttpClient";
import Popup from "../Component/Popup";
import { reactLocalStorage } from "reactjs-localstorage";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
// import { ThreeDots } from 'react-loader-spinner'

// import id from "../../images/icon-15558.svg";
// import ie from "../../images/icon-15559.svg";
import b from "../images/icon-headphone.png";
import { setShow } from "../Redux/reducer/LoginShow";
import { useDispatch, useSelector } from "react-redux";
import { setLoad } from "../Redux/reducer/Loader";
import { HideLoad } from "../Redux/reducer/Loader";

export default function Header() {

  const { cartData } = useSelector((state) => state.Cart);
  const [getAllCategory, setGetAllCategory] = useState([]);
  const [getAllMenu, setGetAllMenu] = useState([])
  const [getAllProduct, setGetAllProduct] = useState([])
  const [getAllSubCatByCat, setgetAllSubCatByCat] = useState([]);
  const [getAllSubCatByAge, setGetAllSubCatByAge] = useState([]);
  const [searchText, setSearchText] = useState("")
  const [buttonpopup, setButtonpopup] = useState("")
  let userData = reactLocalStorage.getObject("userData");
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    // fetchAllCategory();  
    menuFetch()

    fetchSubCategoryByCategory();
    window.scrollTo(0, 0);
  }, []);

  const menuFetch = async () => {
    console.log("de")
    dispatch(setLoad());
    let res = await HttpClient.requestData(
      "frontend/fetchallmenu",
      "POST"
    );
    if (res && res.status) {
      setGetAllMenu(res.data)
      console.log("Menu", res.data);
      dispatch(HideLoad())
    }
  }

  const fetchSubCategoryByCategory = async () => {
    let res = await HttpClient.requestData(
      "frontend/fetchAllcategory",
      "POST"
    );
    if (res && res.status) {
      setgetAllSubCatByCat(res.data);
      console.log("subCat", res.data);
    }
    fetchSubCategoryByAge()
    fetchAllCategory()
    fetchAllProduct()
  };


  const fetchSubCategoryByAge = async () => {
    let res = await HttpClient.requestData("frontend/fetchAllage", "POST");
    // console.log(res);
    if (res && res.status) {
      setGetAllSubCatByAge(res.data);
      console.log("subCatByAge", res);
    }
  };

  const openLoginPage = () => {
    dispatch(setShow());
  };

  const fetchAllCategory = async () => {
    let res = await HttpClient.requestData("admin/fetch_category", "POST");
    if (res && res.status) {
      setGetAllCategory(res.data)
      console.log("AllCategory", res.data);
      // let catArr = []
      // res.data.forEach((item, index) => {
      //   let obj ={
      //     id: index,
      //     name: item.Category_name
      //   }
      //   catArr.push(obj)
      // })
    }
  }

  const fetchAllProduct = async () => {
    let res = await HttpClient.requestData("admin/fetch_product", "POST");
    if (res && res.status) {
      console.log("AllProduct", res.data);
      let prodArr = []
      res.data.forEach((item, index) => {
        let obj = {
          id: index,
          name: item.Product_title

        }
        prodArr.push(obj)
      })
      setGetAllProduct(prodArr)
      console.log("ProductArr...", prodArr);
    }
  }

  const items = getAllProduct

  const handleOnSelect = (item) => {
    // the item selected
    setSearchText(item.name)
    console.log("SelectText", item)
  }

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log("String", string, "Result", results)
  }

  const onSearch = async () => {
    if (searchText !== '') {
      const dataSend = {
        product_name: searchText,
      }
      let res = await HttpClient.requestData("frontend/productsearch", "POST", dataSend);
      console.log(res);
      if (res && res.status) {
        console.log("ProdId", res.data[0].id);
        navigate(`/shopdetails/${res.data[0].id}`)
        setSearchText('')
      }
    }
  }

  // const showSearchInp = () => {
  //   document.querySelector('.search-style-2').classList.remove('hideSearch');
  // }

  // const closeSearchInp = () => {
  //   document.querySelector('.search-style-2').classList.add('hideSearch');
  // }

  return (
    <div>
      {/* <div className="top-header">
        <p>Bestselling Books on Sale - Upto 60% OFF</p>
      </div> */}

      {/* <div className="header-middle">
        <div className="container-fluid">
          <div className="row header-wrap">
            <div className="col-md-12 col-lg-2 col-xl-2 logo logo-width-1 col-12">
              <Link to="/">
                <img src={a} alt="logo" />
              </Link>
            </div>
            <div className="col-md-12 header-right col-12 col-lg-10 col-xl-10">
              <div
                className="col-xl-6 col-lg-6 col-md-12 col-12 search-style-2"
                style={{ padding: 0, border: 'none' }}
              >

                <div style={{ position: "relative" }}>
                  <ReactSearchAutocomplete
                    items={items}
                    onSearch={handleOnSearch}
                    onSelect={handleOnSelect}
                    placeholder={'Search Your Product'}
                  />
                  <a onClick={onSearch} href="#" className="search_icon" style={{ borderRadius: '0 25px 25px 0' }}>
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </a>

                </div>
              </div>
              <div className="col-md-12 col-12 col-xl-6 col-lg-6 header-action-right header-list">
                <div className="ingressBlock">
                  {userData && Object.keys(userData).length > 0 ?
                    <p className="line4">{`Hello, ${userData.name}`}</p> :
                    <p className="line3" onClick={openLoginPage}>Hello, Sign In/Sign Up</p>
                  }
                  {userData && Object.keys(userData).length > 0 ?
                    <Link to={"/account"}><p className="line4">| Account & Lists</p></Link> :
                    null
                  }

                  <p>
                    <a href="#" onClick={() => setButtonpopup(true)}>Want to partner with us?</a>
                  </p>
                  <p>
                    <Link to={'/topWishlist'}>Wishlist</Link>
                  </p>

                </div>
                <Link to="/cart">
                  <div className="ingressBlock">
                    {cartData.length != 0 ? (
                      <div className="countItem">
                        <span className="countPlus">{cartData.length}</span>
                      </div>
                    ) : null}
                    <i class="fa-solid fa-cart-plus text-black" style={{ fontSize: "30px" }}></i>
                    Cart
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="header-middle">
        <nav class="navbar navbar-expand-lg navbar-light">
          <Link class="navbar-brand" to="/">
            <figure className="bookNavLogo">
              <img src={a} alt="logo" />
            </figure>
          </Link>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">

              {/* <li class="nav-item myHeadNavItem">
                <header className="dark">
                  <nav role="navigation">
                    <Link to="javascript:void(0);" className="ic menu" tabIndex={1}>
                      <span className="line" />
                      <span className="line" />
                      <span className="line" />
                    </Link>
                    <a href="javascript:void(0);" className="ic close" />
                    
                    <ul className="main-nav">

                      {getAllMenu.length > 0 ? getAllMenu.map((item, index) => (
                        <li key={index} className={item.category.length > 0 ? "top-level-link custom-li" : "top-level-link"}>
                          <a className="mega-menu">
                            <span className={`${item.category.length > 0 ? "mega_Menuarrow" : "custom-li"}`}>{item.menu_name}</span>
                          </a>
                          
                          <div className="sub-menu-block">
                            <div className="row">
                              {item.category.map((item, index) => (
                                <div
                                  key={index}
                                  className="col-md-12 col-lg-2 col-sm-12 col-xl-2"
                                >
                                  <Link to={`/Shopbycat/${item.id}?catName=${item.Category_name}`}>
                                    <h2 className="sub-menu-head">
                                      {item.Category_name}
                                    </h2>
                                  </Link>
                                  {item.Subcategory.map((element, index) => (
                                    <ul key={index} className="sub-menu-lists">
                                      <Link to={`/shop/${element.id}/${item.id}?subCatName=${element.subcategory_name}`}>
                                        <li>
                                          {element.subcategory_name}
                                        </li>
                                      </Link>
                                    </ul>
                                  ))}
                                </div>
                              ))}
                            </div>
                          </div>
                        </li>
                      )) :
                        null
                      }
                    </ul>
                  </nav>
                </header>
              </li> */}

              <li class="nav-item myHeadNavItem">
                {userData && Object.keys(userData).length > 0 ?
                  <Link to={"/account"}><p className="line4">My Account</p></Link> :
                  null
                }
              </li>
              <li class="nav-item myHeadNavItem">
                <Link onClick={() => setButtonpopup(true)} to='/'>Want to partner with us?</Link>
              </li>
              {/* <li class="nav-item myHeadNavItem">
                <Link to={'/topWishlist'}>Wishlist</Link>
              </li> */}

              <li class="nav-item myHeadNavItem">
                {userData && Object.keys(userData).length > 0 ?
                  <p className="line4">{`Hello, ${userData.name}`}</p> :
                  <p className="line3" onClick={openLoginPage}>Hello, Sign In/Sign Up</p>
                }
              </li>

              {/* <li class="nav-item myHeadNavItem">
                <button className="searchBtn" onClick={showSearchInp}><i class="fa-solid fa-magnifying-glass"></i></button>
              </li> */}

              {/* <li class="nav-item myHeadNavItem">
                <Link to="/cart">
                  <div className="ingressBlock">
                    {cartData.length != 0 ? (
                      <div className="countItem">
                        <span className="countPlus">{cartData.length}</span>
                      </div>
                    ) : null}
                    <i class="fa-solid fa-cart-plus text-black cartIcon"></i>
                    Cart
                  </div>
                </Link>
              </li> */}

            </ul>
          </div>
        </nav>
        {/* <div
          className="search-style-2 hideSearch"
          style={{ padding: '0 40px 0 15px', border: 'none' }}
        > */}
        {/* <div className="closeSearchDiv">
            <button className="closeXmark" onClick={closeSearchInp}><i class="fa-solid fa-xmark"></i></button>
          </div> */}

        {/* <div style={{ position: "relative", }}>
            <ReactSearchAutocomplete
              items={items}
              onSearch={handleOnSearch}
              // onHover={handleOnHover}
              onSelect={handleOnSelect}
              // onFocus={handleOnFocus}
              // autoFocus
              // formatResult={formatResult}
              placeholder={'Search Your Product'}
            />
            <a onClick={onSearch} href="#" className="search_icon" style={{ borderRadius: '0 25px 25px 0' }}>
              <i class="fa fa-search" aria-hidden="true"></i>
            </a>
          </div> */}


        {/* <select class="form-control form-control-lg">
                      <option>All</option>
                      <option>Shop by age</option>
                      <option>Handicaped for you</option>
                      <option>Parent's choice</option>
                    </select> */}
        {/* <input
                      type="text"
                      style={{ minHeight: "50px",padding: "10px" }}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    /> */}
        {/* <select
        className="selectpicker"
        data-show-subtext="false"
        data-live-search="true"
      >
        <option value="">Select</option>
        <option value="Anaesthesia">Anaesthesia</option>
        <option value="Anatomy and Physiology">Anatomy and Physiology</option>
        <option value="Cardiology">Cardiology</option>
        <option value="Clinical Skills">Clinical Skills</option>
      
      </select> */}
        {/* </div> */}


        <div>

        </div>
      </div >


      {/*****bottom-header***** */}
      <div className="header-bottom">

        <div className="bottomNavDiv">
          {/*<button className=" d-flex align-items-center">
            Categories
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth={2}
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              height={20}
              width={20}
              xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="6 9 12 15 18 9" />
            </svg>
          </button>*/}
          <div className="inpBtmSec">
            <div className="inpBtmDiv">
              <input type="search" placeholder="Search product" className="inputBtm" />
              <i className="fa-solid fa-magnifying-glass btmSearch" />
            </div>

            <Link to={'/topWishlist'}>
              <p className="btnNavText btnNavTextM">
                <i className="fa-regular fa-heart" />
                &nbsp; <span className="noneSpan">Wishlist</span>
              </p>
            </Link>

            <Link to="/cart">
              <p className="btnNavText" style={{ position: "relative" }}>
                <i className="fa-solid fa-bag-shopping" />
                &nbsp; <span className="noneSpan">Cart</span>

                {cartData.length != 0 ? (
                  <div className="countItem">
                    <span className="countPlus">{cartData.length}</span>
                  </div>
                ) : null}

              </p>
            </Link>
          </div>
        </div>


        {/* <header className="dark">
            <nav role="navigation">
              <a href="javascript:void(0);" className="ic menu" tabIndex={1}>
                <span className="line" />
                <span className="line" />
                <span className="line" />
              </a>
              <a href="javascript:void(0);" className="ic close" />
              <ul className="main-nav" style={{ minHeight: '83px' }}>

                {getAllMenu.length > 0 ? getAllMenu.map((item, index) => (
                  <li key={index} className={item.category.length > 0 ? "top-level-link custom-li" : "top-level-link"}>
                    <a className="mega-menu">
                      <span className={`${item.category.length > 0 ? "mega_Menuarrow" : "custom-li"}`}>{item.menu_name}</span>
                    </a>
                    <div className="sub-menu-block">
                      <div className="row">
                        {item.category.map((item, index) => (
                          <div
                            key={index}
                            className="col-md-12 col-lg-2 col-sm-12 col-xl-2"
                          >
                            <Link to={`/Shopbycat/${item.id}?catName=${item.Category_name}`}>
                              <h2 className="sub-menu-head">
                                {item.Category_name}
                              </h2>
                            </Link>
                            {item.Subcategory.map((element, index) => (
                              <ul key={index} className="sub-menu-lists">
                                <Link to={`/shop/${element.id}/${item.id}?subCatName=${element.subcategory_name}`}>
                                  <li>
                                    {element.subcategory_name}
                                  </li>
                                </Link>
                              </ul>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </li>
                )) :
                  null
                }
              </ul>
            </nav>
          </header> */}

      </div>

      <Popup trigger={buttonpopup} setTrigger={setButtonpopup} />



    </div >
  );
}
