import React, { useState, useEffect } from "react";
import h from "../../images/1984.jpg";
import c from "../../images/download.jpg";
import carthome from "../../images/cart-green.png";
import HttpClient from "../../utils/HttpClient";
import { useSelector } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { setCartData } from "../../Redux/reducer/Cart";
import { useDispatch } from "react-redux";
import { setShow } from "../../Redux/reducer/LoginShow";
import { Navigate, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { img_url } from "../../utils/HttpClient";

export default function ProductByWishlist() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartData } = useSelector((state) => state.Cart);
  const [productCount, setProductCount] = useState(1);

  const [productByCat, setProductByCat] = useState([]);

  useEffect(() => {
    fetchProductByCat();
  }, []);

  const fetchProductByCat = async () => {
    let user = reactLocalStorage.getObject("userData");
    let dataSend = {
      user_id: user.id,
    };
    let res = await HttpClient.requestData(
      "user/userWishlist_fetch",
      "POST",
      dataSend
    );
    console.log("allPod", res);
    if (res && res.status) {
      setProductByCat(res.data);
    }
  };

  const AddCart = async (id) => {
    // console.log("bal")
    let login = reactLocalStorage.get("login_status");
    let user = reactLocalStorage.getObject("userData");
    if (login) {
      let dataSend = {
        user_id: user.id,
        product_id: id,
        product_count: productCount,
      };
      console.log("data", dataSend);
      let result = await HttpClient.requestData(
        "user/product_add_to_cart",
        "POST",
        dataSend
      );
      // console.log(result);
      if (result && result.status) {
        console.log("result.data", result.data);
        dispatch(setCartData(result.data));
        // alert("Product added in cart!!!");
        toast("Product added to cart!!!")
        // navigate("/cart");
      } else {
        // console.log("error", result)
        // alert(result.error);
        toast.warn(result.error)
      }
    } else {
      // alert("Please Login first")
      dispatch(setShow());
    }
  };
  // const loop = [1, 2, 3, 4, 5];
  return (
    <>
    <ToastContainer/>
    <div>
      <section className="bookwali-product-page-part clearfix">
        <div className="container-fluid">
            {productByCat.length > 0 ? 
            <div className="bookwali-product-main-wrap">
              <h2 style={{fontSize: '25px'}}>Top in wishlist</h2>
              {
                productByCat.map((item, index) => {
                  return (
                    <div key={index} className="bookwali-product-box">
                      <div className="bookwali-product-img">
                        <div className="bookwali-product-badges">
                          <span className="hot">Hot</span>
                        </div>
                        <Link to={`/shopdetails/${item.product_id}`}>
                          <img
                            src={`${img_url}/${item.product_image}`}
                            width="70px"
                            height="110px"
                          />
                        </Link>
                      </div>
                      <div className="bookwali-product-content">
                        {item.language && item.publishers !== null ? (
                          <h4>
                            <Link to={`/shopdetails/${item.product_id}`}>
                              {item.language}, {item.publishers}
                            </Link>
                          </h4>
                        ) : <h4> </h4>}
                        <h2>
                          <Link to={`/shopdetails/${item.product_id}`}>{item.product_name}</Link>
                        </h2>
                        <div className="product-rate-box">
                          <ul className="product-rate-list">
                            <li className="float-left">
                              <a href="#">
                                <i className="fa fa-star" aria-hidden="true" />
                              </a>
                            </li>
                            <li className="float-left">
                              <a href="#">
                                <i className="fa fa-star" aria-hidden="true" />
                              </a>
                            </li>
                            <li className="float-left">
                              <a href="#">
                                <i className="fa fa-star" aria-hidden="true" />
                              </a>
                            </li>
                            <li className="float-left">
                              <a href="#">
                                <i className="fa fa-star" aria-hidden="true" />
                              </a>
                            </li>
                            <li className="float-left">
                              <a href="#">
                                <i className="fa fa-star" aria-hidden="true" />
                              </a>
                            </li>
                            <li className="float-left">
                              <a href="#">
                                <i
                                  className="fa fa-star-half-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                          <span
                            className="font-small"
                            style={{ color: "#B6B6B6", fontSize: 12 }}
                          >
                            (4.0)
                          </span>
                        </div>
                        <span
                          className="font-small byGap"
                          style={{ color: "#B6B6B6", fontSize: 12 }}
                        >By
                          {item.author !== null ? (
                            <Link to={`/shopdetails/${item.product_id}`}> {item.author}</Link>
                          ) : null}
                        </span>
                      </div>
                      {/* <span
                        className="font-small"
                        style={{ color: "#B6B6B6", fontSize: 12 }}
                      >
                        By<a href="#">{item.author}</a>
                      </span> */}
                      <div className="product-card-bottom">
                        <div className="product-price">
                          <span className="mr-2">₹{item.selling_price}</span>
                          <del className="old-price">₹{item.price}</del>
                        </div>
                        <div className="product-add-cart">
                          <a
                            className="product-add-btn"
                            onClick={() => AddCart(item.product_id)}
                          >
                            {/* <img
                              src={carthome}
                              alt="Cart"
                              className="img-fluid"
                              // onClick={}
                            /> */}
                            <i class="fa-solid fa-cart-plus"></i>
                            Add{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                }) 
              }
            </div>:
            <h5 className="noproduct_text">No Product in your wishlist</h5>
            }
        </div>
      </section>
    </div>
    </>
  );
}