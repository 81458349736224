import React from 'react'
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

function NewPagination({ totalPosts, postPerPage, setCurrentPage, currentPage }) {
    let pages = [];
    for (let i = 1; i <= Math.ceil(totalPosts / postPerPage); i++) {
        pages.push(i)
    }
    return (
        <div className='text-center mr-3 mt-3'>
            {currentPage === 1 ? <button disabled style={{border: "1px solid #dee2e6", cursor: "auto", opacity: '.5', padding: '10px', color: '#fff'}} onClick={() => setCurrentPage(pages[0])}><FaAngleDoubleLeft style={{color:"#3bd49c"}}/></button> : <button style={{border: "1px solid #dee2e6", padding: '10px', color: '#fff', borderRadius: '4px'}} onClick={() => setCurrentPage(pages[0])}><FaAngleDoubleLeft style={{color:"#3bd49c"}}/></button>}
            {pages.map((page, index) => {
                return <button style={{border: "1px solid #dee2e6", padding: '10px', color: '#fff', fontWeight: '600', fontSize: '15px', color:"#3bd49c"}} key={index} onClick={() => setCurrentPage(page)} className=
                {`${page === currentPage ? 'active' : ''}` }
                >{page}</button>
            })}
            {currentPage === pages.length ? <button disabled style={{border: "1px solid #dee2e6", cursor: "auto", opacity: '.5', padding: '10px', color: '#fff'}} onClick={() => setCurrentPage(pages.length)}><FaAngleDoubleRight style={{color:"#3bd49c"}}/></button> : <button style={{border: "1px solid #dee2e6", padding: '10px', color: '#fff', borderRadius: '4px'}} onClick={() => setCurrentPage(pages.length)}><FaAngleDoubleRight style={{color:"#3bd49c"}}/></button>}
        </div>
    )
}

export default NewPagination