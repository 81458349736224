import React, { useEffect } from "react";
import Breadcrumb from "../../Component/Breadcrumb";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function ReturnPolicy() {
  const location = useLocation()
  useEffect(() => {
    // alert("kk")
    window.scrollTo(0,0)
    // 👇️ scroll to top on page load
    // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [location.key]);
  return (
    <>
      <Breadcrumb title="Return and Refund Policy" />
      <section className="privacy-area section-tb-padding">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="privacy-title">
                <h1>Return and Refund Policy</h1>
                <p>Last updated: January 23, 2023</p>
                <p>Thank you for shopping at Bookswali.</p>
              </div>
              <div className="interpretation">
                <h1>Interpretation and Definitions</h1>
                <h3>Interpretation</h3>
                <p>
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </p>
                <h3>Definitions</h3>
                <p>For the purposes of this Privacy Policy:</p>
              </div>
              <div className="privacy-content">
                <ul className="privacy-policy">
                  <li>
                    <p>
                      <b>Company</b> (referred to as either "the Company", "We",
                      "Us" or "Our" in this Agreement) refers to Bookswali.com.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Goods</b> refer to the items offered for sale on the
                      Service.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Orders</b> mean a request by You to purchase Goods from
                      Us.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Service</b> refers to the Website.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Website</b> refers to Bookswali.com, accessible from{" "}
                      <Link
                        to="/"
                        style={{
                          fontSize: "18px",
                          textDecoration: "underline",
                          color: "blue",
                        }}
                      >
                        Bookswali.com
                      </Link>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>You</b> means the individual accessing or using the
                      Service, or the company, or other legal entity on behalf
                      of which such individual is accessing or using the
                      Service, as applicable.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="personalData">
                <h1>Your Order Cancellation Rights</h1>
                <p>
                  You are entitled to cancel Your Order within 24 hours without
                  giving any reason for doing so.
                </p>
                <p>
                  The deadline for cancelling an Order is 24 hours from the date
                  on which You received the Goods or on which a third party you
                  have appointed, who is not the carrier, takes possession of
                  the product delivered.
                </p>
                <p>
                  In order to exercise Your right of cancellation, You must
                  inform Us of your decision by means of a clear statement. You
                  can inform us of your decision by:
                </p>
              </div>
              <div className="privacy-content">
                <ul className="privacy-policy">
                  <li>
                    <p>By email: info@bookswali.com</p>
                  </li>
                </ul>
                <p>
                  We will reimburse You no later than 24 hours from the day on
                  which We receive the returned Goods. We will use the same
                  means of payment as You used for the Order, and You will not
                  incur any fees for such reimbursement.
                </p>
              </div>

              <div className="userData">
                <h3>Conditions for Returns</h3>
                <p>
                  In order for the Goods to be eligible for a return, please
                  make sure that:
                </p>
              </div>
              <div className="privacy-content">
                <ul className="privacy-policy">
                  <li>
                    <p>The Goods were purchased in the last 24 hours</p>
                  </li>
                  <li>
                    <p>The Goods are in the original packaging</p>
                  </li>
                </ul>
                <p>The following Goods cannot be returned:</p>
              </div>
              <div className="privacy-content">
                <ul className="privacy-policy">
                  <li>
                    <p>
                      The supply of Goods made to Your specifications or clearly
                      personalized.
                    </p>
                  </li>
                  <li>
                    <p>
                      The supply of Goods which according to their nature are
                      not suitable to be returned, deteriorate rapidly or where
                      the date of expiry is over.
                    </p>
                  </li>
                  <li>
                    <p>
                      The supply of Goods which are not suitable for return due
                      to health protection or hygiene reasons and were unsealed
                      after delivery.
                    </p>
                  </li>
                  <li>
                    <p>
                      The supply of Goods which are, after delivery, according
                      to their nature, inseparably mixed with other items.
                    </p>
                  </li>
                </ul>
                <p>
                  We reserve the right to refuse returns of any merchandise that
                  does not meet the above return conditions in our sole
                  discretion.
                </p>
                <p>
                  Only regular priced Goods may be refunded. Unfortunately,
                  Goods on sale cannot be refunded. This exclusion may not apply
                  to You if it is not permitted by applicable law.
                </p>
              </div>

              <div className="userData">
                <h3>Returning Goods</h3>
                <p>
                  You are responsible for the cost and risk of returning the
                  Goods to Us. You should send the Goods at the following
                  address:
                </p>
                <p>Plot 58-59, Village Kotla,</p>
                <p>Hoshiarpur Road, Jalandhar City; 144025</p>
                <p>
                  We cannot be held responsible for Goods damaged or lost in
                  return shipment. Therefore, We recommend an insured and
                  trackable mail service. We are unable to issue a refund
                  without actual receipt of the Goods or proof of received
                  return delivery.
                </p>
              </div>
              <div className="userData">
                <h3>Gifts</h3>
                <p>
                  If the Goods were marked as a gift when purchased and then
                  shipped directly to you, You'll receive a gift credit for the
                  value of your return. Once the returned product is received, a
                  gift certificate will be mailed to You.
                </p>
                <p>
                  If the Goods weren't marked as a gift when purchased, or the
                  gift giver had the Order shipped to themselves to give it to
                  You later, We will send the refund to the gift giver.
                </p>
              </div>
              <div className="userData">
                <h3>Contact Us</h3>
                <p>
                  If you have any questions about this Privacy Policy, You can
                  contact us:
                </p>
              </div>
              <div className="privacy-content">
                <ul className="privacy-policy">
                  <li>
                    <p>By email: info@bookswali.com</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
