import { createSlice } from '@reduxjs/toolkit'

export const LoginShowSlice = createSlice({
  name: 'loader',
  initialState: {
   show:false
  },
  reducers: {
    setShow(state, action) {
      state.show = true

    },
    HideShow(state){
        state.show=false
    }
  
  }
})
export const { setShow, HideShow } = LoginShowSlice.actions;

export default LoginShowSlice.reducer;