import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Product from "../Home/Product";
import c from "../../images/download.jpg";
import toys from "../../images/banner3.jpg";
import HomeModal from "../../Component/Checkout/Modal/HomeModal";

import Featured from "./Featured";
import Deals from "./Deals";
import Needs from "./Needs";
import AOS from 'aos';
import "aos/dist/aos.css"
import bmg from "../../images/booksbanner.jpg";
import books1 from "../../images/books1.jpg";
import Testimonial from "../Home/Testimonial";

import book from "../../images/books.png";
import stationary from "../../images/stationary.png";
import toy from "../../images/toys.png";
import bannernew from "../../images/banner2.png";
import png5 from "../../images/Png5.png";
import png6 from "../../images/Png6.png";
import png4 from "../../images/Png4.png";
import HttpClient, { PRODUCT_TYPE_IMG } from "../../utils/HttpClient";
// import logo from "../../images/default-image.jpg";
import { useDispatch } from "react-redux";
import { setLoad } from "../../Redux/reducer/Loader";
import { HideLoad } from "../../Redux/reducer/Loader";
import { reactLocalStorage } from "reactjs-localstorage";
import { initCart, setCartData } from "../../Redux/reducer/Cart";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import defaultImg from "../../images/default-image.jpg";
import Stationery from "../../images/Stationery.png"
import { FloatingWhatsApp } from "react-floating-whatsapp";
// import a from "../../src/images/logobookswali.png";
import logo from "../../../src/images/logobookswali.png";
import TopCategories from "../../view/Home/TopCategories";

// Swiper Slider
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';

// Image URLs
import { STATIC_BANNER_IMG } from '../../constants/data';
import FadeInanimation from "../../Component/FadeInSection/FadeInanimation";

export default function Home() {
  useEffect(() => {
    AOS.init({
        duration: 1000,
        delay: 900,
    });
})
  const dispatch = useDispatch();

  const [bannerImg, setBannerImg] = useState([]);
  const [getProductType, setGetProductType] = useState([]);
  const [staticBanner, setStaticBanner] = useState("");
  const [showLoder, setshowLoder] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [popupBanner, setPopupBanner] = useState("");
  const [open, setOpen] = useState(true);

  const onCloseModal = () => setOpen(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getPopUpBanner();
    fetchAllBanner();
    fetchAllStaticBanner();
    fetchAllCartData();
    fetchAllProductType();
  }, []);

  function onLoad() {
    // delay for demo only
    setTimeout(() => setIsLoading(false), 1000);

    // setIsLoading(false)
  }

  const getPopUpBanner = async () => {
    let result = await HttpClient.requestData("admin/fetch_popbanner", "GET");
    // console.log("Pop", result);
    if (result && result.status) {
      setPopupBanner(result.data[0]?.popbanner_image);
      // console.log("Banner...", result.data);
    }
  };

  const fetchAllCartData = async () => {
    let login = reactLocalStorage.get("login_status");
    if (login) {
      let user = reactLocalStorage.getObject("userData");

      let data = {
        user_id: user.id,
      };

      let result = await HttpClient.requestData(
        "user/product_fetch_cart",
        "POST",
        data
      );

      if (result && result.status) {
        dispatch(initCart(result.data));
      }
    }
  };

  const fetchAllBanner = async () => {
    dispatch(setLoad());

    let res = await HttpClient.requestData("frontend/fetchBanner", "POST");
    // console.log(res);
    if (res && res.status) {
      setBannerImg(res.data);
    }
    dispatch(HideLoad());
  };

  const fetchAllStaticBanner = async () => {
    dispatch(setLoad());
    let res = await HttpClient.requestData(
      "frontend/fetchstaticBanner",
      "POST"
    );
    console.log(res);
    if (res && res.status) {
      setStaticBanner(res.data);
      // console.log("staticBanner", res.data);
    }
    dispatch(HideLoad());
  };

  const fetchAllProductType = async () => {
    let res = await HttpClient.requestData("frontend/fetchProductType", "POST");
    console.log("FetchType", typeof res.data);
    if (res && res.status && typeof res.data == "object") {
      setGetProductType(res.data);
    }
  };

  if (showLoder === true) {
    document.body.style.opacity = "0.2";
  } else {
    document.body.style.opacity = "1";
  }

  return (
    <>
      <ToastContainer />
      <div>
        <section className="banner">
          <div className="container-fluid">
            <div className="banner-slide">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                navigation={true}
                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                // }}
                modules={[Autoplay, Navigation]}
                className="myOwnSwiper"
              >

                {bannerImg.length > 0 ? (
                  bannerImg.map((item, index) => {
                    return (
                      <SwiperSlide>
                        <div key={index} className="items">
                          <div class="banner-image">
                            {item.banner_image &&
                              item.banner_image != null ? (
                              <a href="#">
                                <img
                                  src={Stationery}
                                  className="img-fluid"
                                  style={{
                                    display: isLoading ? "block" : "none",
                                  }}
                                  alt=""
                                />
                                <img
                                  src={`${HttpClient.IMG_URL}${item.banner_image}`}
                                  className="img-fluid"
                                  style={{
                                    display: isLoading ? "none" : "block",
                                  }}
                                  alt=""
                                  onLoad={onLoad}
                                />
                              </a>
                            ) : null}

                             
                            <div className="booksdivamacontentdiv" >
                              <h4 className="booksdivamacontentrtxtpp">Bookswali</h4>
                              <Link className="shownoebtnnmadiv" to="/shopprice/999">Shop Now</Link>
                            </div>
                            
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })
                ) : (
                  <div className="items">
                    <div class="banner-image">
                      <a href="#">
                        <img
                          // style={{width: '100vw', height: '100vh'}}
                          src={Stationery}
                          className="img-fluid"
                          alt=""
                        />
                      </a>
                      <div className="booksdivamacontentdiv" >
                              <h4 className="booksdivamacontentrtxtpp">Bookswali</h4>
                              <Link className="shownoebtnnmadiv" to="/shopprice/999">Shop Now</Link>
                            </div>
                    </div>
                  </div>
                )}

              </Swiper>
            </div>

            {/* <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12 customBox">
              <div className="customcontainer">
                <div
                  className="row align-items-center"
                  style={{ justifyContent: "center" }}
                >
                  {getProductType?.map((item, index) => (
                    <div
                      key={index}
                      className="col-lg-3 col-md-3 col-xl-3 col-3 text-center"
                    >
                      <div className="boxImage d-flex align-items-center justify-content-center mb-4 pb-4">
                        <Link
                          to={`/shoptype/${index + 1}`}
                          className="d-block"
                          style={{
                            borderRadius: "50%",
                            overflow: "hidden",
                            maxWidth: "129px",
                            height: "148px",
                            width: "100%",
                            background: "rgb(223 223 223)",
                          }}
                        >
                          <img
                            src={`${PRODUCT_TYPE_IMG}${item.image}`}
                            alt=""
                            className="img-fluid"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}
          </div>
        </section>

        <TopCategories />

        <Featured />

        <Product name="Top Products"/>


        {/* <section>
          <div
            className="container-fluid"
            style={{ paddingLeft: "0", paddingRight: "0" }}
          >
            <div className="row">
              <div className="col-md-12 col-12">
                <h1 className="parentclass">Parents Choice</h1>
                <div className="banner-new">
                  <Link to={"/shoptype/1"}>
                    <img
                      src={`${STATIC_BANNER_IMG}/${staticBanner.banner2}`}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12 customBoxnew">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-xl-4 col-4 text-center">
                      <div className="boxImage">
                        <Link to="/shopprice/999">
                          <img src={png6} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-xl-4 col-4 text-center">
                      <div className="boxImage">
                        <Link to="/shopprice/499">
                          <img src={png5} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-xl-4 col-4 text-center">
                      <div className="boxImage">
                        <Link to="/shopprice/199">
                          <img src={png4} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <div className="container-fluid">
          <section className="toys-banner">
            <Link to="/shoptype/1">
              <img
                src={`${STATIC_BANNER_IMG}/${staticBanner.banner3}`}
              />
            </Link>
          </section>
        </div>
        {/* <Deals /> */}


      
          <Product name="Best Selling Products"/>
  


        <Testimonial />
        {/* <Needs /> */}
      </div>
      <FloatingWhatsApp
        phoneNumber="9888252881"
        accountName="BooksWali"
        avatar={logo}
        notificationSound={true}
      />

      <div id="sandi_modal">
        <HomeModal
          isLoading={isLoading}
          open={open}
          onCloseModal={onCloseModal}
          orderData={popupBanner}
        />
      </div>
    </>
  );
}
