import React from 'react'
import Breadcrumb from '../../Component/Breadcrumb';
import c1 from "../../images/about-10.jpg";


export default function Index() {
    return (
        <>
           <Breadcrumb title="FAQ'S"/>
            <section className="faqs-area section-tb-padding">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="faq-title">
                                <h1>FAQ's</h1>
                                <p>Below are often requested questions, you can locate the solution for yourself</p>
                            </div>
                            <div className="faq-box">
                                <ul className="faq-ul">
                                    <li className="faq-li">
                                        <h3><span>1.</span>What is bookswali's go back policy?</h3>
                                        <span className="faq-desc">Our 100% customer safety software permits for clean returns Only for merchandise now no longer as in step with description/picturegraph at the broken faulty damaged" condition.</span>
                                    </li>
                                    <li className="faq-li">
                                        <h3><span>2.</span> How to elevate dispute for an order?</h3>
                                        <span className="faq-desc">You can improve a dispute inside 7 days of shipping, in with the aid of using sending an e-mail to support@mikshaa.com with pix of damaged faulty broken 7 days of...</span>
                                    </li>
                                    <li className="faq-li">
                                        <h3><span>3.</span>When will i be refunded?</h3>
                                        <span className="faq-desc">Self deliver orders We will refund while your object again from warehouse. The refund quantity wii be credited inside three to five running days on your Mikshaa Acoount.</span>
                                    </li>
                                </ul>
                                <ul className="faq-ul">
                                    <li className="faq-li">
                                        <h3><span>4.</span> What are the transport charges?</h3>
                                        <span className="faq-desc">Delivery price varies with every product. Most of product FREE Most of product FREE shipping in India shipping price varies with every product. Most of shipping in India.</span>
                                    </li>
                                    <li className="faq-li">
                                        <h3><span>5.</span> What is the expected shipping time?</h3>
                                        <span className="faq-desc">The estimated time of delivery is within 7 working days for domestic orders and 15-20 working days for international orders. All 4 to 5 days from the warehouse.</span>
                                    </li>
                                    <li className="faq-li">
                                        <h3><span>6.</span> How will the shipping be done?</h3>
                                        <span className="faq-desc">We try and method all deliveries via reputed courier groups like Bluedart, Aramex,E-come, DTDC, DHL and Fedex. In a few cases, your pincode with the aid of using these.</span>
                                    </li>
                                </ul>
                                <a href="#" className="btn-style1">Still have a question?</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
