import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Checkout() {
  const { cartData } = useSelector((state) => state.Cart);
  const totalAmount = useMemo(() => {
    return cartData.reduce((a, b) => parseInt(a) + parseInt(b.total_price), 0);
  }, [cartData]);
  console.log("amount", totalAmount);

  console.log("CartData", cartData);

  return (
    <div className="col-lg-4">
      <div
        className="border p-md-4 cart-totals ml-3"
        style={{ borderRadius: 10 }}
      >
        <div className="table-responsive">
          <table className="table no-border">
            <tbody>
              <tr>
                <td className="cart_total_label">
                  <h6 className="text-muted">Subtotal</h6>
                </td>
                <td className="cart_total_amount">
                  <h4 className="text-brand text-end">Rs. {totalAmount}.00</h4>
                </td>
              </tr>
              <tr>
                <td scope="col" colSpan={2}>
                  <div className="divider-2 mt-1 mb-1" />
                </td>
              </tr>
              <tr>
                <td className="cart_total_label">
                  <h6 className="text-muted">Shipping</h6>
                </td>
                <td className="cart_total_amount">
                  <h5 className="text-heading text-end">Free </h5>
                </td>
              </tr>
              <tr>
                <td className="cart_total_label">
                  <h6 className="text-muted">Estimate for</h6>
                </td>
                <td className="cart_total_amount">
                  <h5 className="text-heading text-end">INDIA</h5>
                </td>
              </tr>
              <tr>
                <td scope="col" colSpan={2}>
                  <div className="divider-2 mt-1 mb-1" />
                </td>
              </tr>
              <tr>
                <td className="cart_total_label">
                  <h6 className="text-muted">Total</h6>
                </td>
                <td className="cart_total_amount">
                  <h4 className="text-brand text-end">Rs. {totalAmount}.00</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {cartData.length > 0 ? (
          <Link to="/checkout-page" className="btn roky-bttn mb-2 w-100">
            Proceed To CheckOut
            <i className="fa-solid fa-arrow-right-from-bracket ml-2" />
          </Link>
        ) : (
          <button to="/checkout-page" className="btn roky-bttn mb-2 w-100" disabled>
            Proceed To CheckOut
            <i className="fa-solid fa-arrow-right-from-bracket ml-2" />
          </button>
        )}
      </div>
    </div>
  );
}

export default Checkout;
