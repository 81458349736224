import React, { useState, useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import { SIDE_IMG_URL, BANNER_IMG_URL } from '../../../utils/HttpClient';


function HomeModal({ isLoading, open, onCloseModal, orderData }) {

  return (
    <>
      {/* <div className="home_modal_area">
        <Modal open={open} onClose={onCloseModal} center>
          <div className='homepopup-image'>
            <img className="img-fluid" src={`${SIDE_IMG_URL}${orderData}`} alt="PopupImg" />
          </div>
        </Modal>
      </div> */}
      {
        // isLoading ? null : (<div className="home_modal_area">
        // <Modal open={open} onClose={onCloseModal} center styles={{ position:'fixed', padding:0, margin:0, top:0, left:0, width: '100%', height: '100%' }}>
          // <div className='homepopup-image'>
            // <img className="img-fluid" src={`${BANNER_IMG_URL}${orderData}`} alt="PopupImg" />
          // </div>
        // </Modal>
      // </div>)
      }
    </>
  );
}

export default HomeModal