import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';


function ModalManu({open, onCloseModal, orderData}) {

  return (
    <>
      <Modal open={open} onClose={onCloseModal} center style={{background: "#fff"}}>
        <h2>Shipment Information</h2>
        <p>
          Your orderId is- {orderData.order_id} and shipmentId is- {orderData.shipment_id} .Note the document for tracking...
        </p>
      </Modal>
    </>
  );
}

export default ModalManu