import React, { useState, useEffect } from "react";
import Popular from "../../Component/Popular";
import Parent from "../../Component/Parent";
import Day from "../../Component/Day";
import Needs from "../Home/Needs";
import breadcumbimg from "../../images/shop-grid-banner-img.jpg";
import { useLocation, useParams } from "react-router";
import HttpClient from "../../utils/HttpClient";
import Subcat from "../../Component/Subcat";
import SubcatAge from "../../Component/SubcatAge";
import Catprod from "../../Component/Catprod";
import { useDispatch } from "react-redux";
import { setLoad } from "../../Redux/reducer/Loader";
import { HideLoad } from "../../Redux/reducer/Loader";
import { Link, useSearchParams } from "react-router-dom";

// URLs
import { STATIC_BANNER_IMG } from '../../constants/data';
import Stationery from "../../images/Stationery.png"
function ProductByType() {
  const dispatch = useDispatch();
  const [staticBanner, setStaticBanner] = useState("");
  const [productType, setProductType] = useState("")
  useEffect(() => {
    fetchAllStaticBanner()
  }, [])

  const location = useLocation()
  useEffect(() => {
    // alert("kk")
    window.scrollTo(0, 0)
    // 👇️ scroll to top on page load
    // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [location.key]);

  const params = useParams();
  const [param] = useSearchParams()
  let catName = param.get('catName')
  let subCatName = param.get('subCatName')
  console.log("Cat...", catName);
  console.log("SubCat...", subCatName);
  let id = params.id;
  let priceId = params.priceId;
  let cat = params.cat
  let age = params.age
  let sub = params.sub
  let catId = params.catId
  console.log(age);

  const fetchAllProductType = async () => {
    let res = await HttpClient.requestData(
      "frontend/fetchstaticBanner",
      "POST"
    );
    if (res && res.status) {
      setProductType()
    }
  }

  const fetchAllStaticBanner = async () => {
    dispatch(setLoad());
    let res = await HttpClient.requestData(
      "frontend/fetchstaticBanner",
      "POST"
    );
    console.log(res);
    if (res && res.status) {
      setStaticBanner(res.data);
      // console.log("staticBanner", res.data);
    }
    dispatch(HideLoad());
  };
  // console.log(id);
  // const [productByType, setProductByType] = useState([])

  // const fetchAllProductByType = async () => {
  //   let res = await HttpClient.requestData("frontend/fetchAllProduct", "POST");
  //   console.log(res);
  //   if (res && res.status) {
  //     setProductByType(res.data)
  //     console.log(res.data);
  //   }
  // }

  return (
    <div>
      <section className=""  >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-12">

              <div className="bookwali-shop-grid-banner-box bokkaswaloshopnabnnerumage" style={{
                // backgroundImage: `url('${STATIC_BANNER_IMG}/${Stationery}')`,

                backgroundImage: `url('${Stationery}')`
              }}>
                <div
                  className="shop-grid-banner-list-btn-wrap_sjhopdtlbnn"
                  style={{ alignItems: "center" }}
                >
                  <ul className="shop-grid-banner-list">
                    <h1 style={{ color: "#fff" }}>{id === "3" ? 'Books' : id === "2" ? 'Toys' : id === "1" ? 'Stationary' : catName !== null ? catName : subCatName !== '' ? subCatName : null}</h1>
                    <li className="float-left">
                      <Link to="/" className="shop-grid-hover-text ">
                        <span className="">
                          <i className="fa fa-home" aria-hidden="true" />
                        </span>
                        Home /
                      </Link>
                    </li>
                    {/* <li className="float-left">
                        <span className="">
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                            style={{ fontSize: 10 }}
                          />
                        </span>
                      </li> */}
                    <li className="float-left">
                      <a href="#" className="shop-list-totsldibulmaionliaatxtpp">Shop</a>
                    </li>
                    {/* <li className="float-left ">
                        <span className="shop-grid-hover-text">
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                            style={{ fontSize: 10 }}
                          />
                        </span>
                      </li> */}
                    <li className="float-left">
                      <a href="#">{id === "1" ? 'Books' : id === "2" ? 'Toys' : id === "3" ? 'Stationary' : catName !== null ? catName : subCatName !== '' ? subCatName : null}</a>
                    </li>
                  </ul>
                  {/* <div className="shop-grid-banner-btn-box">
                      <a href="#" className="btn shop-grid-banner-btn">
                        <i className="fa fa-times" aria-hidden="true" />
                        Books
                      </a>
                      <a href="#" className="btn shop-grid-banner-btn">
                        <i className="fa fa-times" aria-hidden="true" />
                        Books
                      </a>
                      <a href="#" className="btn shop-grid-banner-btn">
                        <i className="fa fa-times" aria-hidden="true" />
                        Books
                      </a>
                      <a href="#" className="btn shop-grid-banner-btn">
                        <i className="fa fa-times" aria-hidden="true" />
                        Books
                      </a>
                      <a href="#" className="btn shop-grid-banner-btn">
                        <i className="fa fa-times" aria-hidden="true" />
                        Books
                      </a>
                    </div> */}
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      {/* <Popular id={id} /> */}
      {id === "1" || id === "2" || id === "3" ? (
        <Popular id={id} />
      ) :
        priceId === "999" || priceId === "499" || priceId === "199" ?
          <Parent id={priceId} /> : age ? <SubcatAge sub={sub} age={age} /> : cat ? <Subcat id={id} cat={cat} /> :
            <Catprod catId={catId} />}
    </div>
  );
}

export default ProductByType;
