import React, { useState, useEffect } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import Breadcrumb from "../../Component/Breadcrumb";
import c1 from "../../images/about-10.jpg";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function Index() {
  const location = useLocation()
  useEffect(() => {
    // alert("kk")
    window.scrollTo(0,0)
    // 👇️ scroll to top on page load
    // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [location.key]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setaddress] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const onChangePhoneNumber = (val) => {
    var pattern = new RegExp(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
    );

    if (!pattern.test(val)) {
      setError(true);
      setPhone(val);
    } else if (phone.length !== 9) {
      setError(true);
      setPhone(val);
    } else {
      setError(false);
      setPhone(val);
    }
  };

  const submitContact = async () => {
    const userData = reactLocalStorage.getObject("userData");
    if (name !== "" && email !== "" && phone !== "" && message !== "") {
      let dataSend = {
        user_id: userData.id,
        name: name,
        email: email,
        phone: phone,
        message: message,
      };
      if (address !== "") {
        dataSend.address = address
      }
      console.log("Contact", dataSend);
      let res = await HttpClient.requestData(
        "user/addContactDetails",
        "POST",
        dataSend
      );
      console.log("GetContact", res);
      if (res && res.status) {
        toast("Contact added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setName("");
        setEmail("");
        setPhone("");
        setaddress("");
        setMessage("");
        setTimeout(function () {
          navigate("/");
        }, 2000);
      } else {
        toast("Contact already exists! Try a new one...", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      toast("All Fields Required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      <Breadcrumb title={"Contact Us"} />

      <section className="contact-sec">
        <div className="contact-us">
          <div className="container">
            <div className="contact-area">
              <div className="row">
                <div className="col-md-12">
                  <div className="icon-wrap">
                    <div className="icon-box">
                      <i class="fa fa-phone" aria-hidden="true"></i>
                    </div>
                    <div className="icon-cnt">
                      <h3>Phone</h3>
                      <h6>
                        Mobile No: <span>+91-981-555-2881</span>
                      </h6>
                      <h6>
                        Email:<span>care@bookswali.com</span>{" "}
                      </h6>
                      {/* <h6>
                        care@bookswali.com
                      </h6> */}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="icon-wrap">
                    <div className="icon-box">
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                    </div>
                    <div className="icon-cnt">
                      <h3>Phone</h3>
                      <h6>
                        Toll-Free: <span>0000 - 123 - 456789</span>
                      </h6>
                      <h6>
                        Fax:<span>0000 - 123 - 456789</span>{" "}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="icon-wrap">
                    <div className="icon-box">
                      <i class="fa fa-location-arrow" aria-hidden="true"></i>
                    </div>
                    <div className="icon-cnt">
                      <h3>Phone</h3>
                      <h6>
                        Toll-Free: <span>0000 - 123 - 456789</span>
                      </h6>
                      <h6>
                        Fax:<span>0000 - 123 - 456789</span>{" "}
                      </h6>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="lft-part-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3407.318191299469!2d75.60565941444865!3d31.350197562663386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a5a0f40b54eed%3A0xe6da0efd2fb46082!2sFOREVER%20EDUCATION!5e0!3m2!1sen!2sin!4v1675317282087!5m2!1sen!2sin"
                    width="450"
                    height="450"
                    style={{border: '0'}}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="rgt-part-form">
                  <h2>Contact Form</h2>
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Phone"
                        pattern="[1-9]{1}[0-9]{9}"
                        value={phone}
                        onChange={(e) => onChangePhoneNumber(e.target.value)}
                      />
                      {error && (
                        <p style={{ color: "red" }}>
                          phone number must be valid
                        </p>
                      )}
                    </div>
                    
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Address"
                        value={address}
                        onChange={(e) => setaddress(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <textarea
                        className="form-control"
                        placeholder="Message"
                        id="exampleFormControlTextarea1"
                        rows={6}
                        defaultValue={""}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <button
                      onClick={submitContact}
                      type="button"
                      class="btn  mb-2 mt-3"
                    >
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
