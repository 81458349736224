import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
// import png5 from "../../images/Png5.png";
// import png6 from "../../images/Png6.png";
// import png4 from "../../images/Png4.png";
import Stationery from "../../images/Stationery.png";
import Toy from "../../images/Toy.png";
import Book from "../../images/Book.png";
import FadeInanimation from '../../Component/FadeInSection/FadeInanimation';

function TopCategories() {
    // useEffect(() => {
    //     AOS.init({
    //       duration: 1000,
    //       once: false,    
    //       easing: 'ease-in-out', 
    //     });
    //   }, []);
    //   useEffect(() => {
    //     AOS.refresh();
    //   })


    return (
        <>

          
                <section className="roundImgSec">
                    <div className="container-fluid">

                        {/* <div class="sectionhead">
                        <h4>xxxxxxxxxx</h4>
                    </div> */}
                        {/* 
                    <div className="roundContain">

                        <div className="colFigRound">
                            <Link to="/shopprice/999">
                                <figure className="roundImgFig">
                                    <img src={png6} alt="" className="img-fluid" />
                                </figure>
                            </Link>
                            <h6 className="roundImgText">Stationery</h6>
                        </div>

                        <div className="colFigRound">
                            <Link to="/shopprice/499">
                                <figure className="roundImgFig">
                                    <img src={png5} alt="" className="img-fluid" />
                                </figure>
                            </Link>
                            <h6 className="roundImgText">Toy</h6>
                        </div>

                        <div className="colFigRound">
                            <Link to="/shopprice/199">
                                <figure className="roundImgFig">
                                    <img src={png4} alt="" className="img-fluid" />
                                </figure>
                            </Link>
                            <h6 className="roundImgText">Book</h6>
                        </div>

                    </div> */}

                        <div className="sectionhead">
                            <h4>SHOP BY COLLECTIONS</h4>
                        </div>
                        <FadeInanimation fadeUp={true}>
                        <div className='shopbycollectiondivflx'>
                            <div className='shopbycollectiondivmain' data-aos="fade-left"
                                data-aos-anchor="#example-anchor"
                                data-aos-offset="500"
                                data-aos-duration="900">
                                <Link className='shopbycollectiondivbg' to="/shopprice/999">
                                    <figure className='shopbycollectiondivimg'>
                                        <img src={Stationery} />

                                        <div className='shopbycollectiondivbgbottom'>
                                            <div className=''>
                                                <p className='shopbycollectiondivbgtxtt'>Stationary</p>
                                            </div>
                                        </div>
                                        <button className='bktxtpppdivmainexprt'>
                                          Shop Now
                                        </button>
                                    </figure>
                                </Link>

                            </div>
                            <div className='shopbycollectiondivmain' data-aos="fade-right"
                                data-aos-anchor="#example-anchor"
                                data-aos-offset="500"
                                data-aos-duration="900">
                                <Link className='shopbycollectiondivbg' to="/shopprice/499">
                                    <figure className='shopbycollectiondivimg'>
                                        <img src={Toy} />

                                        <div className='shopbycollectiondivbgbottom'>
                                            <div className=''>
                                                <p className='shopbycollectiondivbgtxtt'>Toy</p>
                                            </div>
                                        </div>
                                        <button className='bktxtpppdivmainexprt'>
                                        Shop Now

                                        </button>
                                    </figure>


                                </Link>

                            </div>

                            <div className='shopbycollectiondivmain' data-aos="fade-down"
                                data-aos-anchor="#example-anchor"
                                data-aos-offset="500"
                                data-aos-duration="900">
                                <Link className='shopbycollectiondivbg' to="/shopprice/199">
                                    <figure className='shopbycollectiondivimg'>
                                        <img src={Book} />

                                        <div className='shopbycollectiondivbgbottom'>
                                            <div className=''>
                                                <p className='shopbycollectiondivbgtxtt'>Book</p>
                                            </div>
                                        </div>
                                        <button className='bktxtpppdivmainexprt'>
                                        Shop Now

                                        </button>
                                    </figure>


                                </Link>

                            </div>
                        </div>
                        </FadeInanimation>

                    </div>
                </section>


        </>
    )
}

export default TopCategories