import React from 'react'
import c1 from "../images/about-10.jpg";

export default function Breadcrumb(props) {
  return (
   <>
    <section className='contact-crumb' style={{ backgroundImage: `url('${c1}')`, position: 'relative' }}>
                <div className='container'>
                    <div>
                        <h1>{props.title}</h1>
                    </div>
                </div>
            </section>
   </>
  )
}
