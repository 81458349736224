import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import book from "../../images/babybooks.jpg";
import HttpClient from "../../utils/HttpClient";
import { initCart, removeItem, setCartData } from "../../Redux/reducer/Cart";
import { useDispatch } from "react-redux";
import { setShow } from "../../Redux/reducer/LoginShow";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { img_url } from "../../utils/HttpClient";
import { useLocation } from "react-router";
import { removeAllItem } from '../../Redux/reducer/Cart';

function CartData() {
  const cartData = useSelector((state) => state.Cart.cartData);
  const dispatch = useDispatch();
  console.log("cart data-------", cartData);
  console.log("Cart", cartData);
  const [emptyCart, setEmptyCart] = useState("")

  useEffect(() => {
    fetchCartData();
  }, []);
  const fetchCartData = async () => {
    let user = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "user/product_fetch_cart",
      "POST",
      { user_id: user.id }
    );
    console.log("cart data", result);
    if (result && result.status) {
      dispatch(initCart(result.data));
    }
  };

  const increaseItem = async (id, count) => {
    // alert("increase")
    // let user = reactLocalStorage.getObject("userData")
    let login = reactLocalStorage.get("login_status");
    if (login) {
      let dataSend = {
        cart_id: id,
        // user_id:user.id,
        // product_id:id,
        // product_count:parseInt(count )+ parseInt(1)
        // product_count:1
      };
      console.log("increase", dataSend);
      let result = await HttpClient.requestData(
        "user/cart_update",
        "POST",
        dataSend
      );
      console.log("Ins", result);
      if (result && result.status) {
        fetchCartData();
      } else {
        toast.warn("something error");
      }
    } else {
      dispatch(setShow());
    }
  };
  const decreaseItem = async (id, count) => {
    // alert("decrease")
    // let user = reactLocalStorage.getObject("userData")
    let login = reactLocalStorage.get("login_status");
    if (login) {
      let dataSend = {
        cart_id: id,
        // user_id:user.id,
        // product_id:id,
        // product_count:parseInt(count )- parseInt(1)
      };
      console.log("increase", dataSend);
      let result = await HttpClient.requestData(
        "user/cartCountDecrease",
        "POST",
        dataSend
      );
      console.log("dcr", result);
      if (result && result.status) {
        fetchCartData();
        if (typeof result.data == "string") {
          setEmptyCart(result.data)
          dispatch(removeAllItem([]))
        }
      } else {
        toast.warn("something error");
      }
    } else {
      dispatch(setShow());
    }
  };

  const RemoveItem = async (id) => {
    let data = {
      cart_id: id,
    };
    let result = await HttpClient.requestData(
      "user/delete_single_cart",
      "POST",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      dispatch(removeItem(id));
      toast.success("Product removed successfully!")
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="table-responsive shopping-summery">
        <table className="table table-wishlist">
          <thead>
            <tr className="main-heading">
              <th className="custome-checkbox start pl-3">
                {/* <input
              className="form-check-input"
              type="checkbox"
              name="checkbox"
              id=""
              defaultValue=""
            /> */}
                <label className="form-check-label" htmlFor="exampleCheckbox11" />
              </th>
              <th scope="col" colSpan={2}>
                Product
              </th>
              <th scope="col">Unit Price</th>
              <th scope="col">Quantity</th>
              <th scope="col">Subtotal</th>
              <th scope="col" className="end">
                Remove
              </th>
            </tr>
          </thead>
          <tbody>
            {emptyCart === "" ?
              cartData.map((item, index) => {
                return (
                  <tr className="pt-3" key={item.id}>
                    <td className="custome-checkbox pl-3">
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheckbox1"
                      />
                    </td>
                    <td className="image product-thumbnail pt-4">
                      <img src={`${img_url}/${item.product_image}`} alt="#" />
                    </td>
                    <td className="product-des product-name">
                      <h6 className="mb-1">
                        <a className="product-name mb-10 text-heading" href="#">
                          {item.product_name}
                        </a>
                      </h6>
                    </td>
                    <td className="price" data-title="Price">
                      <h4 className="text-body">Rs. {item.actual_price}</h4>
                    </td>
                    <td className="text-center detail-info" data-title="Stock">
                      <div className="detail-extralink mr-1">
                        <div className="detail-qty border radius">
                          <a href="#" className="qty-down">
                            <i
                              className="fa-solid fa-angle-down"
                              onClick={() =>
                                decreaseItem(item.id, item.product_count)
                              }
                            />
                          </a>
                          <span className="qty-val">{item.product_count}</span>
                          <a href="#" className="qty-up">
                            <i
                              className="fa-solid fa-angle-up"
                              onClick={() =>
                                increaseItem(item.id, item.product_count)
                              }
                            />
                          </a>
                        </div>
                      </div>
                    </td>
                    <td className="price" data-title="Price">
                      <h4 className="text-brand">Rs. {item.total_price}</h4>
                    </td>
                    <td className="action text-center" data-title="Remove">
                      <a href="#" className="text-body">
                        <i
                          className="fa-solid fa-trash-can"
                          onClick={() => RemoveItem(item.id)}
                        />
                      </a>
                    </td>
                  </tr>
                );
              }) : null}
            {/* <tr>
          <td className="custome-checkbox pl-3">
            <input
              className="form-check-input"
              type="checkbox"
              name="checkbox"
              id=""
              defaultValue=""
            />
            <label
              className="form-check-label"
              htmlFor="exampleCheckbox2"
            />
          </td>
          <td className="image product-thumbnail">
            <img src={book} alt="#" />
          </td>
          <td className="product-des product-name">
            <h6 className="mb-1">
              <a className="product-name mb-1 text-heading" href="#">
                    Board Books for Kids
              </a>
            </h6>
            <div className="product-rate-cover">
              <div className="product-rate d-inline-block">
                <div
                  className="product-rating"
                  style={{ width: "90%" }}
                ></div>
              </div>
              <span className="font-small ml-2 text-muted"> (4.0)</span>
            </div>
          </td>
          <td className="price" data-title="Price">
            <h4 className="text-body">Rs. 200.00 </h4>
          </td>
          <td className="text-center detail-info" data-title="Stock">
            <div className="detail-extralink mr-1">
              <div className="detail-qty border radius">
                <a href="#" className="qty-down">
                  <i className="fa-solid fa-angle-down" />
                </a>
                <span className="qty-val">1</span>
                <a href="#" className="qty-up">
                  <i className="fa-solid fa-angle-up" />
                </a>
              </div>
            </div>
          </td>
          <td className="price" data-title="Price">
            <h4 className="text-brand">Rs. 200.00 </h4>
          </td>
          <td className="action text-center" data-title="Remove">
            <a href="#" className="text-body">
              <i className="fa-solid fa-trash-can" />
            </a>
          </td>
        </tr> */}
            {/* <tr>
          <td className="custome-checkbox pl-3">
            <input
              className="form-check-input"
              type="checkbox"
              name="checkbox"
              id="#"
              defaultValue=""
            />
            <label
              className="form-check-label"
              htmlFor="exampleCheckbox3"
            />
          </td>
          <td className="image product-thumbnail">
            <img src={book} alt="#" />
          </td>
          <td className="product-des product-name">
            <h6 className="mb-1">
              <a className="product-name mb-1 text-heading" href="#">
                  Board Books for Kids
              </a>
            </h6>
            <div className="product-rate-cover">
              <div className="product-rate d-inline-block">
                <div
                  className="product-rating"
                  style={{ width: "90%" }}
                ></div>
              </div>
              <span className="font-small ml-2 text-muted"> (4.0)</span>
            </div>
          </td>
          <td className="price" data-title="Price">
            <h4 className="text-body">Rs. 99.00 </h4>
          </td>
          <td className="text-center detail-info" data-title="Stock">
            <div className="detail-extralink mr-1">
              <div className="detail-qty border radius">
                <a href="#" className="qty-down">
                  <i className="fa-solid fa-angle-down" />
                </a>
                <span className="qty-val">1</span>
                <a href="#" className="qty-up">
                  <i className="fa-solid fa-angle-up" />
                </a>
              </div>
            </div>
          </td>
          <td className="price" data-title="Price">
            <h4 className="text-brand">Rs 99.00 </h4>
          </td>
          <td className="action text-center" data-title="Remove">
            <a href="#" className="text-body">
              <i className="fa-solid fa-trash-can" />
            </a>
          </td>
        </tr> */}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default CartData;
