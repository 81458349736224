import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import h from "../images/1984.jpg";
import ia from "../images/icon-15555.svg";
import ib from "../images/icon-15556.svg";
import ic from "../images/icon-15557.svg";
import id from "../images/icon-15558.svg";
import ie from "../images/icon-15559.svg";
import book from "../images/book.jpeg";
import HttpClient from "../utils/HttpClient";
import cartgreen from "../images/cart-green.png";
import { useSelector } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { setCartData } from "../Redux/reducer/Cart";
import { useDispatch } from "react-redux";
import Pagination from "./Pagination/Pagination";
import NewPagination from "./Pagination/NewPagination";

export default function Subcat(props) {
  // const user = useSelector((state)=>state.User.login_status)
  const { cartData } = useSelector((state) => state.Cart);

  const dispatch = useDispatch();
  let id = props.id;
  let cat = props.cat;
  // console.log(id);
  // console.log(cat);
  const [productBySubcat, setProductBySubcat] = useState([]);
  const [productCount, setProductCount] = useState(1);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [postPerPage, setPostPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);


  useEffect(() => {
    fetchAllProductBySubcat();
  }, [props]);

  const fetchAllProductBySubcat = async () => {
    let dataSend = {
      category_id: cat,
      age_id: cat,
      subcategory_id: id,
    };
    let res = await HttpClient.requestData(
      "frontend/fetchAllProduct",
      "POST",
      dataSend
    );
    console.log("allProducts", res);
    if (res && res.status) {
      setProductBySubcat(res.data);
      setPageCount(Math.ceil(res.data.length / 10));
      // setProductByType(res.data);
      console.log("ProductBySubcat", res.data);
    }
  };

  const AddCart = async (id) => {
    let login = reactLocalStorage.get("login_status");
    let user = reactLocalStorage.getObject("userData");
    if (login) {
      let dataSend = {
        user_id: user.id,
        product_id: id,
        product_count: productCount,
      };
      console.log("data", dataSend);
      let result = await HttpClient.requestData(
        "user/product_add_to_cart",
        "POST",
        dataSend
      );
      console.log(result);
      if (result && result.status) {
        dispatch(setCartData([...cartData, result.data]));
      }
    } else {
      alert("Please Login first");
    }
  };

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const currentPost = productBySubcat.slice(firstPostIndex, lastPostIndex);

  return (
    <div>
      <section className="bookwali-product-page-part clearfix">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              {/* {productBySubcat.length > 0 ? (
                <p className="found-text" style={{fontSize: '21px', fontWeight: '600'}}>
                  We found{" "}
                  <strong class="text-brand">{productBySubcat.length}</strong>{" "}
                  items for you!
                </p>
              ) : (
                <p className="found-text text-center" style={{fontSize: '21px', fontWeight: '600'}}>
                  No Product Found
                </p>
              )} */}

              <div class="sectionhead">
                {productBySubcat.length > 0 ? (
                  <h4>
                    We found{" "}
                    <strong class="text-brand">{productBySubcat.length}</strong>{" "}
                    items for you!
                  </h4>
                ) : (
                  <h4>
                    No Product Found
                  </h4>
                )}
              </div>

              <div className="bookwali-product-main-wrap-single">
                {currentPost.length > 0 &&
                  currentPost.map((item, index) => {
                    return (
                      <div key={index} className="bookwali-product-box-single">
                        <Link to={"/shopdetails/" + item.product_id}>
                          <div className="bookwali-productbg">
                            <div className="bookwali-product-img">
                              {/* <div className="bookwali-product-badges">
                              <span className="hot">Hot</span>
                            </div> */}
                              <img
                                src={`https://skychat.easytodb.com/Bookswali/public/images/${item.main_img}`}
                                width="70px"
                                height="110px"
                              />
                            </div>
                          </div>
                          <div className="bookwali-product-content">
                            {item.language && item.publishers ? (
                              <h4>
                                <a href="#">
                                  {item.language}, {item.publishers}
                                </a>
                              </h4>
                            ) : null}

                            <h2>
                              <a href="#">{item.Product_title}</a>
                            </h2>
                            <div className="product-rate-box">
                              <ul className="product-rate-list">
                                <li className="float-left">
                                  <a href="#">
                                    <i
                                      className="fa fa-star"
                                      aria-hidden="true"
                                    />
                                  </a>
                                </li>
                                <li className="float-left">
                                  <a href="#">
                                    <i
                                      className="fa fa-star"
                                      aria-hidden="true"
                                    />
                                  </a>
                                </li>
                                <li className="float-left">
                                  <a href="#">
                                    <i
                                      className="fa fa-star"
                                      aria-hidden="true"
                                    />
                                  </a>
                                </li>
                                <li className="float-left">
                                  <a href="#">
                                    <i
                                      className="fa fa-star"
                                      aria-hidden="true"
                                    />
                                  </a>
                                </li>
                                <li className="float-left">
                                  <a href="#">
                                    <i
                                      className="fa fa-star"
                                      aria-hidden="true"
                                    />
                                  </a>
                                </li>
                                <li className="float-left">
                                  <a href="#">
                                    <i
                                      className="fa fa-star-half-o"
                                      aria-hidden="true"
                                    />
                                  </a>
                                </li>
                              </ul>
                              <span
                                className="font-small"
                                style={{ color: "#B6B6B6", fontSize: 12 }}
                              >
                                (4.0)
                              </span>
                            </div>
                            <span
                              className="font-small"
                              style={{ color: "#B6B6B6", fontSize: 12 }}
                            >
                              {item.author ? (
                                <>
                                  By<a href="#">{item.author}</a>
                                </>
                              ) : null}
                            </span>
                          </div>
                          {/* <span
                          className="font-small"
                          style={{ color: "#B6B6B6", fontSize: 12 }}
                        >
                          {item.author ? (
                            <>
                              By<a href="#">{item.author}</a>
                            </>
                          ) : null}
                        </span> */}
                          <div className="product-card-bottom">
                            <div className="product-price">
                              <span className="mr-2">
                                ₹{item.selling_price}
                              </span>
                              <del className="old-price">₹{item.price}</del>
                            </div>
                            <div className="product-add-cart">
                              <a
                                className="product-add-btn"
                                href="#"
                                onClick={() => AddCart(item.product_id)}
                              >
                                {/* <i className="fa fa-shopping-cart" aria-hidden="true" /> */}
                                {/* <img
                                src={cartgreen}
                                alt="Cart"
                                className="img-fluid"
                              /> */}
                                <i class="fa-solid fa-cart-plus"></i>
                                Add{" "}
                              </a>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
              </div>
            </div>
            {/* <div className="col-md-3 col-12">
              <div className="primary-sidebar ">
                <div className="theiaStickySidebar">
                  <div className="sidebar-widget widget-category-2 mb-30">
                    <h5 className="section-title style-1 mb-30">Category</h5>
                    <ul>
                      <li>
                        <a href="#">
                          {" "}
                          <img src={ia} alt="" />
                          Study
                        </a>
                        <span className="count">30</span>
                      </li>
                      <li>
                        <a href="#">
                          {" "}
                          <img src={ib} alt="" />
                          Play
                        </a>
                        <span className="count">35</span>
                      </li>
                      <li>
                        <a href="#">
                          {" "}
                          <img src={ic} alt="" />
                          Read{" "}
                        </a>
                        <span className="count">42</span>
                      </li>
                      <li>
                        <a href="#">
                          {" "}
                          <img src={id} alt="" />
                          Celebrate
                        </a>
                        <span className="count">22</span>
                      </li>
                      <li>
                        <a href="#">
                          {" "}
                          <img src={ie} alt="" />
                          Decorate
                        </a>
                        <span className="count">4</span>
                      </li>
                    </ul>
                  </div>

                  <div
                    className="banner-img wow fadeIn mb-lg-0 animated d-lg-block d-none"
                    style={{ visibility: "hidden", animationName: "none" }}
                  >
                    <img src="assets/imgs/banner/banner-11.png" alt="" />
                    <div className="banner-text">
                      <span>Oganic</span>
                      <h4>
                        Save 17% <br />
                        on <span className="text-brand">Oganic</span>
                        <br />
                        Juice
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* {productBySubcat.length > 0 ? (
            <Pagination pageCount={pageCount} />
          ) : null} */}
          {currentPost.length > 0 &&
            <NewPagination
              totalPosts={productBySubcat.length}
              postPerPage={postPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          }
        </div>
      </section>
    </div>
  );
}
