import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import book from "../../images/images-book.jpg";
import book1 from "../../images/pmmmm_img.jpg";
import c from "../../images/download.png";
import book2 from "../../images/investing-book-img.jpg";
import book3 from "../../images/lyndee_walker.jpg";
import book4 from "../../images/devdas-book-img.jpg";
import book5 from "../../images/images.g.jpg";
import book6 from "../../images/images.d.jpg";
import book7 from "../../images/images.b.jpg";
import book8 from "../../images/1984.jpg";
import book9 from "../../images/images-again.jpg";
import d from "../../images/download.jpg";
import overlay from "../../images/bannerSliderlayer.gif";
import booknew from "../../images/Png5.png";
import stationary from "../../images/Png6.png";
import toy from "../../images/Png4.png";
import HttpClient from "../../utils/HttpClient";
import defaultImg from "../../images/default-image.jpg";
import time from "../../images/timer_a73398.svg";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation  } from 'swiper/modules';
import { SIDE_IMG_URL, STATIC_BANNER_IMG } from '../../constants/data'
import FadeInanimation from "../../Component/FadeInSection/FadeInanimation";
export default function Featured() {
  const [sideBannerImg, setSideBannerImg] = useState([]);
  const [staticBanner, setStaticBanner] = useState([]);
  const [hotDeal, setHotDeal] = useState([]);

  useEffect(() => {
    fetchHotDeals();
  }, []);

  const fetchAllSideBanner = async () => {
    let res = await HttpClient.requestData("frontend/fetchsideBanner", "POST");
    console.log(res);
    if (res && res.status) {
      setSideBannerImg(res.data);
    }
  };

  const fetchAllStaticBanner = async () => {
    let res = await HttpClient.requestData(
      "frontend/fetchstaticBanner",
      "POST"
    );
    console.log(res);
    if (res && res.status) {
      setStaticBanner(res.data);
      console.log("staticBanner", res.data);
    }
  };

  const fetchHotDeals = async () => {
    let dataSend = {
      status: "hot_deals",
    };
    let res = await HttpClient.requestData(
      "frontend/fetchAllProduct",
      "POST",
      dataSend
    );
    console.log(res);
    if (res && res.status) {
      setHotDeal(res.data);
      // console.log("HotDeal", res.data);
    }
    fetchAllSideBanner();
    fetchAllStaticBanner();
  };

  const navigate = useNavigate();
  const loop = [1, 2, 3];

  const loop1 = [
    {
      color: "rgb(216 241 224)",
      img: book1,
    },
    {
      color: "#FFFCEB",
      img: book2,
    },
    {
      color: "#FEEFEA",
      img: book3,
    },
    {
      color: " #FFF3FF",
      img: book4,
    },
    {
      color: "#FFF3EB",
      img: book5,
    },
    {
      color: "#F2FCE4",
      img: book6,
    },
    {
      color: "#FEEFEA",
      img: book7,
    },
    {
      color: "#F4F6FA",
      img: book8,
    },
    {
      color: "#BCE3C9",
      img: book,
    },
    {
      color: "#FEEFEA",
      img: book9,
    },
    {
      color: "#FFF3EB",
      img: book5,
    },
    {
      color: "#FEEFEA",
      img: book3,
    },
    {
      color: "rgb(216 241 224)",
      img: book1,
    },
  ];
  return (
    <section className="hotdeals_area">
      <div className="container-fluid">
        <div className="hot-deals">
          <div className="row mb-2">
            <div className="col-md-12 col-xl-10 col-lg-10 col-sm-12 col-xs-12 col-12">
              <div class="sectionhead">
                <h4>Hot Deals</h4>
              </div>
              <FadeInanimation fadeUp>
              <section className="featured-categories clearfix">
                <Swiper
                  slidesPerView={5}
                  spaceBetween={10}
                  loop={true} 
                  // autoplay={{
                  //   delay: 2500,
                  //   disableOnInteraction: false,
                  // }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    // 466: {
                    //   slidesPerView: 2,
                    //   spaceBetween: 20,
                    // },
                    300: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    400: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    690:{
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    700: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    991: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                    1366: {
                      slidesPerView: 5,
                      spaceBetween: 30,
                    },
                  }}
                  className="myHotDealsSwiper"
                >

                  {hotDeal.map((item, index) => {
                    return (

                      <SwiperSlide>
                        <div key={index} className="feature-part-show">
                          <Link to={`/shopdetails/${item.product_id}`}>
                            <div
                              className="featured-categories_slide"
                            // style={{ backgroundColor: item.color }}
                            >
                              <figure className="img-hover-scale overflow-hidden">
                                <a href="#">
                                  <img
                                    src={`https://api.bookswali.com/public/images/${item.main_img}`}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </a>
                              </figure>
                              <div style={{ margin: "0 20px" }}>
                                <h6>
                                  <a href="#" tabIndex={0}>
                                    {item.Product_title}
                                  </a>
                                </h6>
                                <p className="product_des">when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                {/* <div class="_3LU4EM">{item.product_type}</div> */}
                                <div class="_2tDhp2">
                                  Upto{" "}
                                  {Math.floor((item.discount / item.price) * 100)}%
                                  off
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </section>
              </FadeInanimation>
            </div>
            <div
              className="col-md-12 col-xl-2 col-lg-2 col-sm-12 col-xs-12 col-12"
              style={{ alignContent: "center", }}
            >
              {sideBannerImg && Object.keys(sideBannerImg).length !== 0 ? (
                <div className="elementor-widget-container">
                  <div className="mf-elementor-banner-small">
                    <img
                      src={`${SIDE_IMG_URL}${sideBannerImg.image}`}
                      className="img-fluid"
                    />
                    {/* <div className="banner-content">
                    <h2 className="banner-title">{sideBannerImg.title}</h2>
                    <div className="banner-desc">
                      <p>{sideBannerImg.description}</p>
                    </div>
                  </div> */}
                  </div>
                </div>
              ) : (
                <div className="elementor-widget-container">
                  <div class="mf-elementor-banner-small">
                    <a href="#">
                      <img
                        // style={{width: '100vw', height: '100vh'}}
                        src={defaultImg}
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-xl-12 col-lg-12 col-12">
            {
              <div className="slideshow-slide__overlay">
                <img
                  src={`${STATIC_BANNER_IMG}/${staticBanner.banner1}`}
                  alt=""
                  className="img-fluid"
                  onClick={() => navigate("/shop")}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </section>
  );
}
