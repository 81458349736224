import React, { useState } from "react";
import "./Loginregister.css";
import a from "../../images/logobookswali.png";
import { useDispatch } from "react-redux";
import HttpClient from "../../utils/HttpClient";
import { setuser } from "../../Redux/reducer/User";
import { reactLocalStorage } from "reactjs-localstorage";
import { useNavigate } from "react-router";
import { HideShow } from "../../Redux/reducer/LoginShow";
import { toast } from "react-toastify";

export default function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");

  const submitHandle = async (e) => {
    e.preventDefault();
    if (
      email !== "" &&
      password !== "" &&
      name !== "" &&
      address !== "" &&
      phone !== ""
    ) {
      let emailRegex = /^([\-\.0-9a-zA-Z]+)@([\-\.0-9a-zA-Z]+)\.([a-zA-Z]){1,7}$/;
      if(emailRegex.test(email)){
        if(password.length >= 8){
          if(phone.length === 10){
            let dataSend = {
              name,
              phone,
              address,
              email,
              password,
            };
            // console.log(dataSend)
            // return false
            let result = await HttpClient.requestData(
              "user/userRegistration",
              "POST",
              dataSend
            );
            if (result && result.status) {
              dispatch(setuser(result.data));
              reactLocalStorage.set("login_status", true);
              reactLocalStorage.setObject("userData", result.data);
              toast(" User Signed Up Successfully! ", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              dispatch(HideShow());
              // navigate("/")
            } else {
              toast("Email already exists! Try a new one...", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
          else {
            toast.error("Phone no should be 10 numbers!")
          }
        }
        else {
          toast.error("Password should be minimum 8 characters ")
        }
      }
      else {
        toast.error("Enter a valid email!")
      }
      
    } else {
      toast("All fields are required...", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div className="form-container sign-up-container">
      <form action="#" className="mobile-form">
        <h1>Create Account</h1>
        <span>or use your email for registration</span>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(val) => setName(val.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(val) => setEmail(val.target.value)}
        />
        <input
          type="number"
          pattern="(7|8|9)\d{9}"
          placeholder="Enter 10-digit mobile number"
          value={phone}
          onChange={(val) => setPhone(val.target.value.slice(0, 10))}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(val) => setPassword(val.target.value)}
        />
        <textarea
          rows={4}
          cols={50}
          name="comment"
          placeholder="Enter Address here..."
          value={address}
          onChange={(val) => setAddress(val.target.value)}
        />
        <button onClick={submitHandle}>Sign Up</button>
      </form>
    </div>
  );
}
