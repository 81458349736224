import React from 'react'
import Needs from "../Home/Needs"
import xy from "../../images/about-img1.webp";
import x1 from "../../images/999999999.jpg";
import ia from "../../images/icon-15555.svg";
import ib from "../../images/icon-15556.svg";
import ic from "../../images/icon-15557.svg";
import id from "../../images/icon-15558.svg";
import ie from "../../images/icon-15559.svg";
import ig from "../../images/icon-15560.svg";
import lady from "../../images/Our__performance_img.png";
import wave from "../../images/wave.png"
import house from "../../images/house.png";
import cust1 from "../../images/girl-store.png";
import cust2 from "../../images/boy-store.png";
import asia from "../../images/asia-fevrourite.png";
import ab from "../../images/left-dotted.png";
import abc from "../../images/right-dotted.png";
import abcd from "../../images/more-then-products.png";
import abcde from "../../images/more-then-200000.png";
import Breadcrumb from '../../Component/Breadcrumb';

export default function index() {

  return (
    <>
      <Breadcrumb title="About Us" />

      <section className="privacy-area section-tb-padding">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="privacy-title">
                <h1>About Us</h1>
                <p>
                  Being in these industries for many years, we thought to create such a platform so that parents find it super easy to buy their children’s school books while sitting at home rather to stand in queues outside the book shops for long hours. In spite of such valuable efforts done by parents, they used to get the books on printed rate i.e., on <b>M.R.P.</b> So, considering parent’s demand, we have created this online platform for school books named <b>www.bookswali.com.</b>
                </p>
                <p style={{ margin: "8px 0" }}>
                  At <b>Bookswali.com,</b> Our core focus is to provide vast collection of school books online on <b>discount.</b> Our mission is simple: To provide you with a great selection of kids’ school books for unbeatable prices at your ease. This is our mission, our passion behind this platform.
                </p>
                <p><b>Bookswali.com</b> makes it easy and fun to shop for school books, stationery items and toys... We strive to set an example in our industry by continuously growing and innovating with your support. </p>
                <p style={{ margin: "8px 0" }}>
                  We are committed to providing superior customer service to our customers and our partners, to providing a positive and joyful work environment for our colleagues, and to working towards community literacy and education. We strive to provide the highest level of service to our customers.
                </p>
                <p>
                  We view customer service as part of the heart and soul of our company, and think of it as truly our privilege to serve our customers. <b>We believe we are not just selling books, rather we are impacting the lives of millions of people both locally and across the globe.</b>
                </p>
                <p style={{ margin: "8px 0" }}>
                  We promise you'll always be able to find plenty of good Books, Toys, and Stationeries. We look forward to many more years together. At Bookswali.com, you can expect an enjoyable shopping experience with a wonderful Customer Service team who is happy to support you if needed.
                </p>
                <p>
                  I, Deepika Chhabra have selected this name i.e., bookswali.com. It’s my first step towards this online platform hoping your valuable support.
                </p>
              </div>
            </div>
          </div>


          {/* <div className='bacImg'>
            <img src={asia} className="img-fluid" />
          </div> */}
          {/* <div className="aboutus-more">
            <img
              src={ab}
              alt="more then 200000 products"
              className="aboutus-more-than"
            />
            <img
              src={abcd}
              alt="more then 200000 products"
              className="aboutus-more-than"
            />
            <img
              src={abc}
              alt="more then 200000 products"
              className="aboutus-more-than"
            />
            <div className="blue-bg clearfix">
              <div className="row">
                <div className="div-white white1" />
                <div className="div-white white2" />
                <div className="div-white white3" />
                <div className="div-white white4" />
                <div className="div-white white5" />
                <div className="div-white white6" />
                <div className="div-white white7" />
                <div className="div-white white8" />
                <div className="div-white white9" />
                <div className="div-white white10" />
              </div>
              <div className=" row2">
                <div className="div-white white20" />
                <div className="div-white white19" />
                <div className="div-white white18" />
                <div className="div-white white17" />
                <div className="div-white white16" />
                <div className="div-white white15" />
                <div className="div-white white14" />
                <div className="div-white white13" />
                <div className="div-white white12 div-yellow" />
                <div className="div-white white11" />
              </div>
            </div>
          </div> */}

          {/* <div className='gfghh mt-5'>
            <div className="row mb-3">
              <div className="div-white white1" />
              <div className="div-white white2" />
              <div className="div-white white3" />
              <div className="div-white white4" />
              <div className="div-white white5" />
              <div className="div-white white6" />
              <div className="div-white white7" />
              <div className="div-white white8" />
              <div className="div-white white9" />
              <div className="div-white white10" />
            </div>
            <div>
              <img
                src={abcd}
                alt="more then 200000 products"
                className="aboutus-more-than"
              />
              <img
                src={abcde}
                alt="more then 200000 products"
                className="aboutus-more-than1"
              />
            </div>
            <div className=" row2 mt-3">
              <div className="div-white white20" />
              <div className="div-white white19" />
              <div className="div-white white18" />
              <div className="div-white white17" />
              <div className="div-white white16" />
              <div className="div-white white15" />
              <div className="div-white white14" />
              <div className="div-white white13" />
              <div className="div-white white12 div-yellow" />
              <div className="div-white white11" />
            </div>
            <div className='left1'>
              <img
                src={ab}
                alt="more then 200000 products"
                className="aboutus-more-than3"
              />
            </div>
            <div className='right1'>
              <img
                src={abc}
                alt="more then 200000 products"
                className="aboutus-more-than4"
              />
            </div>
          </div> */}

        </div>
      </section>

      {/* <section className="what_we__provide clearfix">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="we__provide_head">
                <h2 className="style-3" style={{ backgroundImage: `url('₹{wave}')`, position: 'relative' }}>What We Provide?</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 mb-3 mt-3">
              <div className="featured-card">
                <img src={ia} alt="tag" />
                <h4>Best Prices &amp; Offers</h4>
                <p>
                  There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form
                </p>
                <a href="#">Read more</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3 mt-3">
              <div className="featured-card">
                <img src={ib} alt="tag" />
                <h4>Wide Assortment</h4>
                <p>
                  There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form
                </p>
                <a href="#">Read more</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3 mt-3">
              <div className="featured-card">
                <img src={ic} alt="tag" />
                <h4>Free Delivery</h4>
                <p>
                  There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form
                </p>
                <a href="#">Read more</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3 mt-3">
              <div className="featured-card">
                <img src={id} alt="tag" />
                <h4>Easy Returns</h4>
                <p>
                  There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form
                </p>
                <a href="#">Read more</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3 mt-3">
              <div className="featured-card">
                <img src={ie} alt="tag" />
                <h4>100% Satisfaction</h4>
                <p>
                  There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form
                </p>
                <a href="#">Read more</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3 mt-3">
              <div className="featured-card">
                <img src={ig} alt="tag" />
                <h4>Great Daily Deal</h4>
                <p>
                  There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form
                </p>
                <a href="#">Read more</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='firstcry'>
        <div className='store-house'>
          <img src={house} />
          <img src={cust1} className="aboutusGirl" />
          <img src={cust2} className="aboutusBoy" />

        </div>
        <div class="container">
          <div className='aboutus-stores'>
            <div className='row'>

            </div>
          </div>
        </div>
      </section>

      <section className="Our__performance clearfix">
        <div className="container">
          <div className="row align-items-center mb-5">
            <div className="col-lg-7">
              <div className="Our__performance__img">
                <img src={lady} alt="image" />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="Our__performance__text">
                <h4 className="mb-3 text-muted">Our performance</h4>
                <p className="mb-5">
                  Ed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                  quae ab illo inventore veritatis et quasi architecto
                </p>
                <p>
                  Pitatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo
                  enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                  fugit, sed quia
                </p>
              </div>
            </div>
          </div>

        </div>
      </section> */}


    </>
  )
}
