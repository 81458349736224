import React from 'react'
import { Link } from "react-router-dom"
import book from "../../images/babybooks.jpg";
import CartData from './CartData';
import { useDispatch, useSelector } from 'react-redux';
import Checkout from '../../Component/Checkout/Checkout';
import HttpClient from '../../utils/HttpClient';
import { reactLocalStorage } from 'reactjs-localstorage';
import { setCartData } from '../../Redux/reducer/Cart';
import { removeAllItem } from '../../Redux/reducer/Cart';

export default function Cart() {
  const cartData = useSelector((state) => state.Cart.cartData)
  const dispatch = useDispatch()

  const clearCart = async () => {
    let user = reactLocalStorage.getObject("userData")
    let result = await HttpClient.requestData("user/delete_user_cart", "POST", { user_id: user.id })
    if (result && result.status) {
      dispatch(removeAllItem([]))
    }
  }
  return (
    <div>

      <div className="container-fulid">
        <section className="your-cart clearfix">
          <div className="page-header breadcrumb-wrap">
            <div className="breadcrumb">
              <a href="#" rel="">
                <i className="fa-solid fa-house-user mr-1" />
                Home
              </a>
              <span /> Shop <span /> Cart
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-8 mb-4">
              <h1 className="heading-2 mb-1">Your Cart</h1>
              <div className="d-flex justify-content-between">
                <h6 className="text-body">
                  There are <span className="text-brand">{`${cartData.length === 1 ? `${cartData.length} product` : `${cartData.length} products`}`}</span> in your
                  cart
                </h6>
                <h6 className="text-body" onClick={clearCart}>
                  <a className="text-muted" onClick={clearCart}>
                    <i className="fa-solid fa-trash-can mr-2" />
                    Clear Cart
                  </a>
                </h6>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-12">
              <CartData />
              <div className="divider-2 mb-4" />
              <div className="cart-action d-flex justify-content-between">
                <Link to={'/'} className="btn mr-1 mb-2">
                  <i className="fa-solid fa-arrow-left-long mr-1" />
                  Continue Shopping
                </Link>
                {/* <a className="btn  mr-1 mb-2">
            <i className="fa-solid fa-arrows-rotate mr-1" />
            Update Cart
          </a> */}
              </div>
              {/* <div className="row mt-5">
          <div className="col-lg-7">
            <div
              className="calculate-shiping p-4 border"
              style={{ borderRadius: 10 }}
            >
              <h4 className="mb-1">Calculate Shipping</h4>
              <p className="mb-3">
                <span className="font-lg text-muted">Flat rate: </span>
                <strong className="text-brand"> 5%</strong>
              </p>
              <form className="field_form shipping_calculator">
                <div className="form-row">
                  <div className="form-group col-lg-12">
                    <div className="custom_select">
                      <select className="form-control select-active w-100">
                        <option value="" data-select2-id={9}>
                          United Kingdom
                        </option>
                        <option value="AX" data-select2-id={22}>
                          Aland Islands
                        </option>
                        <option value="AF" data-select2-id={23}>
                          Afghanistan
                        </option>
                        <option value="AL" data-select2-id={24}>
                          Albania
                        </option>
                        <option value="DZ" data-select2-id={25}>
                          Algeria
                        </option>
                        <option value="AD" data-select2-id={26}>
                          Andorra
                        </option>
                        <option value="AO" data-select2-id={27}>
                          Angola
                        </option>
                        <option value="AI" data-select2-id={28}>
                          Anguilla
                        </option>
                        <option value="AQ" data-select2-id={29}>
                          Antarctica
                        </option>
                        <option value="AG" data-select2-id={30}>
                          Antigua and Barbuda
                        </option>
                        <option value="AR" data-select2-id={31}>
                          Argentina
                        </option>
                        <option value="AM" data-select2-id={32}>
                          Armenia
                        </option>
                        <option value="AW" data-select2-id={33}>
                          Aruba
                        </option>
                        <option value="AU" data-select2-id={34}>
                          Australia
                        </option>
                        <option value="AT" data-select2-id={35}>
                          Austria
                        </option>
                        <option value="AZ" data-select2-id={36}>
                          Azerbaijan
                        </option>
                        <option value="BS" data-select2-id={37}>
                          Bahamas
                        </option>
                        <option value="BH" data-select2-id={38}>
                          Bahrain
                        </option>
                        <option value="BD" data-select2-id={39}>
                          Bangladesh
                        </option>
                        <option value="BB" data-select2-id={40}>
                          Barbados
                        </option>
                        <option value="BY" data-select2-id={41}>
                          Belarus
                        </option>
                        <option value="PW" data-select2-id={42}>
                          Belau
                        </option>
                        <option value="BE" data-select2-id={43}>
                          Belgium
                        </option>
                        <option value="BZ" data-select2-id={44}>
                          Belize
                        </option>
                        <option value="BJ" data-select2-id={45}>
                          Benin
                        </option>
                        <option value="BM" data-select2-id={46}>
                          Bermuda
                        </option>
                        <option value="BT" data-select2-id={47}>
                          Bhutan
                        </option>
                        <option value="BO" data-select2-id={48}>
                          Bolivia
                        </option>
                        <option value="BQ" data-select2-id={49}>
                          Bonaire, Saint Eustatius and Saba
                        </option>
                        <option value="BA" data-select2-id={50}>
                          Bosnia and Herzegovina
                        </option>
                        <option value="BW" data-select2-id={51}>
                          Botswana
                        </option>
                        <option value="BV" data-select2-id={52}>
                          Bouvet Island
                        </option>
                        <option value="BR" data-select2-id={53}>
                          Brazil
                        </option>
                        <option value="IO" data-select2-id={54}>
                          British Indian Ocean Territory
                        </option>
                        <option value="VG" data-select2-id={55}>
                          British Virgin Islands
                        </option>
                        <option value="BN" data-select2-id={56}>
                          Brunei
                        </option>
                        <option value="BG" data-select2-id={57}>
                          Bulgaria
                        </option>
                        <option value="BF" data-select2-id={58}>
                          Burkina Faso
                        </option>
                        <option value="BI" data-select2-id={59}>
                          Burundi
                        </option>
                        <option value="KH" data-select2-id={60}>
                          Cambodia
                        </option>
                        <option value="CM" data-select2-id={61}>
                          Cameroon
                        </option>
                        <option value="CA" data-select2-id={62}>
                          Canada
                        </option>
                        <option value="CV" data-select2-id={63}>
                          Cape Verde
                        </option>
                        <option value="KY" data-select2-id={64}>
                          Cayman Islands
                        </option>
                        <option value="CF" data-select2-id={65}>
                          Central African Republic
                        </option>
                        <option value="TD" data-select2-id={66}>
                          Chad
                        </option>
                        <option value="CL" data-select2-id={67}>
                          Chile
                        </option>
                        <option value="CN" data-select2-id={68}>
                          China
                        </option>
                        <option value="CX" data-select2-id={69}>
                          Christmas Island
                        </option>
                        <option value="CC" data-select2-id={70}>
                          Cocos (Keeling) Islands
                        </option>
                        <option value="CO" data-select2-id={71}>
                          Colombia
                        </option>
                        <option value="KM" data-select2-id={72}>
                          Comoros
                        </option>
                        <option value="CG" data-select2-id={73}>
                          Congo (Brazzaville)
                        </option>
                        <option value="CD" data-select2-id={74}>
                          Congo (Kinshasa)
                        </option>
                        <option value="CK" data-select2-id={75}>
                          Cook Islands
                        </option>
                        <option value="CR" data-select2-id={76}>
                          Costa Rica
                        </option>
                        <option value="HR" data-select2-id={77}>
                          Croatia
                        </option>
                        <option value="CU" data-select2-id={78}>
                          Cuba
                        </option>
                        <option value="CW" data-select2-id={79}>
                          CuraÇao
                        </option>
                        <option value="CY" data-select2-id={80}>
                          Cyprus
                        </option>
                        <option value="CZ" data-select2-id={81}>
                          Czech Republic
                        </option>
                        <option value="DK" data-select2-id={82}>
                          Denmark
                        </option>
                        <option value="DJ" data-select2-id={83}>
                          Djibouti
                        </option>
                        <option value="DM" data-select2-id={84}>
                          Dominica
                        </option>
                        <option value="DO" data-select2-id={85}>
                          Dominican Republic
                        </option>
                        <option value="EC" data-select2-id={86}>
                          Ecuador
                        </option>
                        <option value="EG" data-select2-id={87}>
                          Egypt
                        </option>
                        <option value="SV" data-select2-id={88}>
                          El Salvador
                        </option>
                        <option value="GQ" data-select2-id={89}>
                          Equatorial Guinea
                        </option>
                        <option value="ER" data-select2-id={90}>
                          Eritrea
                        </option>
                        <option value="EE" data-select2-id={91}>
                          Estonia
                        </option>
                        <option value="ET" data-select2-id={92}>
                          Ethiopia
                        </option>
                        <option value="FK" data-select2-id={93}>
                          Falkland Islands
                        </option>
                        <option value="FO" data-select2-id={94}>
                          Faroe Islands
                        </option>
                        <option value="FJ" data-select2-id={95}>
                          Fiji
                        </option>
                        <option value="FI" data-select2-id={96}>
                          Finland
                        </option>
                        <option value="FR" data-select2-id={97}>
                          France
                        </option>
                        <option value="GF" data-select2-id={98}>
                          French Guiana
                        </option>
                        <option value="PF" data-select2-id={99}>
                          French Polynesia
                        </option>
                        <option value="TF" data-select2-id={100}>
                          French Southern Territories
                        </option>
                        <option value="GA" data-select2-id={101}>
                          Gabon
                        </option>
                        <option value="GM" data-select2-id={102}>
                          Gambia
                        </option>
                        <option value="GE" data-select2-id={103}>
                          Georgia
                        </option>
                        <option value="DE" data-select2-id={104}>
                          Germany
                        </option>
                        <option value="GH" data-select2-id={105}>
                          Ghana
                        </option>
                        <option value="GI" data-select2-id={106}>
                          Gibraltar
                        </option>
                        <option value="GR" data-select2-id={107}>
                          Greece
                        </option>
                        <option value="GL" data-select2-id={108}>
                          Greenland
                        </option>
                        <option value="GD" data-select2-id={109}>
                          Grenada
                        </option>
                        <option value="GP" data-select2-id={110}>
                          Guadeloupe
                        </option>
                        <option value="GT" data-select2-id={111}>
                          Guatemala
                        </option>
                        <option value="GG" data-select2-id={112}>
                          Guernsey
                        </option>
                        <option value="GN" data-select2-id={113}>
                          Guinea
                        </option>
                        <option value="GW" data-select2-id={114}>
                          Guinea-Bissau
                        </option>
                        <option value="GY" data-select2-id={115}>
                          Guyana
                        </option>
                        <option value="HT" data-select2-id={116}>
                          Haiti
                        </option>
                        <option value="HM" data-select2-id={117}>
                          Heard Island and McDonald Islands
                        </option>
                        <option value="HN" data-select2-id={118}>
                          Honduras
                        </option>
                        <option value="HK" data-select2-id={119}>
                          Hong Kong
                        </option>
                        <option value="HU" data-select2-id={120}>
                          Hungary
                        </option>
                        <option value="IS" data-select2-id={121}>
                          Iceland
                        </option>
                        <option value="IN" data-select2-id={122}>
                          India
                        </option>
                        <option value="ID" data-select2-id={123}>
                          Indonesia
                        </option>
                        <option value="IR" data-select2-id={124}>
                          Iran
                        </option>
                        <option value="IQ" data-select2-id={125}>
                          Iraq
                        </option>
                        <option value="IM" data-select2-id={126}>
                          Isle of Man
                        </option>
                        <option value="IL" data-select2-id={127}>
                          Israel
                        </option>
                        <option value="IT" data-select2-id={128}>
                          Italy
                        </option>
                        <option value="CI" data-select2-id={129}>
                          Ivory Coast
                        </option>
                        <option value="JM" data-select2-id={130}>
                          Jamaica
                        </option>
                        <option value="JP" data-select2-id={131}>
                          Japan
                        </option>
                        <option value="JE" data-select2-id={132}>
                          Jersey
                        </option>
                        <option value="JO" data-select2-id={133}>
                          Jordan
                        </option>
                        <option value="KZ" data-select2-id={134}>
                          Kazakhstan
                        </option>
                        <option value="KE" data-select2-id={135}>
                          Kenya
                        </option>
                        <option value="KI" data-select2-id={136}>
                          Kiribati
                        </option>
                        <option value="KW" data-select2-id={137}>
                          Kuwait
                        </option>
                        <option value="KG" data-select2-id={138}>
                          Kyrgyzstan
                        </option>
                        <option value="LA" data-select2-id={139}>
                          Laos
                        </option>
                        <option value="LV" data-select2-id={140}>
                          Latvia
                        </option>
                        <option value="LB" data-select2-id={141}>
                          Lebanon
                        </option>
                        <option value="LS" data-select2-id={142}>
                          Lesotho
                        </option>
                        <option value="LR" data-select2-id={143}>
                          Liberia
                        </option>
                        <option value="LY" data-select2-id={144}>
                          Libya
                        </option>
                        <option value="LI" data-select2-id={145}>
                          Liechtenstein
                        </option>
                        <option value="LT" data-select2-id={146}>
                          Lithuania
                        </option>
                        <option value="LU" data-select2-id={147}>
                          Luxembourg
                        </option>
                        <option value="MO" data-select2-id={148}>
                          Macao S.A.R., China
                        </option>
                        <option value="MK" data-select2-id={149}>
                          Macedonia
                        </option>
                        <option value="MG" data-select2-id={150}>
                          Madagascar
                        </option>
                        <option value="MW" data-select2-id={151}>
                          Malawi
                        </option>
                        <option value="MY" data-select2-id={152}>
                          Malaysia
                        </option>
                        <option value="MV" data-select2-id={153}>
                          Maldives
                        </option>
                        <option value="ML" data-select2-id={154}>
                          Mali
                        </option>
                        <option value="MT" data-select2-id={155}>
                          Malta
                        </option>
                        <option value="MH" data-select2-id={156}>
                          Marshall Islands
                        </option>
                        <option value="MQ" data-select2-id={157}>
                          Martinique
                        </option>
                        <option value="MR" data-select2-id={158}>
                          Mauritania
                        </option>
                        <option value="MU" data-select2-id={159}>
                          Mauritius
                        </option>
                        <option value="YT" data-select2-id={160}>
                          Mayotte
                        </option>
                        <option value="MX" data-select2-id={161}>
                          Mexico
                        </option>
                        <option value="FM" data-select2-id={162}>
                          Micronesia
                        </option>
                        <option value="MD" data-select2-id={163}>
                          Moldova
                        </option>
                        <option value="MC" data-select2-id={164}>
                          Monaco
                        </option>
                        <option value="MN" data-select2-id={165}>
                          Mongolia
                        </option>
                        <option value="ME" data-select2-id={166}>
                          Montenegro
                        </option>
                        <option value="MS" data-select2-id={167}>
                          Montserrat
                        </option>
                        <option value="MA" data-select2-id={168}>
                          Morocco
                        </option>
                        <option value="MZ" data-select2-id={169}>
                          Mozambique
                        </option>
                        <option value="MM" data-select2-id={170}>
                          Myanmar
                        </option>
                        <option value="NA" data-select2-id={171}>
                          Namibia
                        </option>
                        <option value="NR" data-select2-id={172}>
                          Nauru
                        </option>
                        <option value="NP" data-select2-id={173}>
                          Nepal
                        </option>
                        <option value="NL" data-select2-id={174}>
                          Netherlands
                        </option>
                        <option value="AN" data-select2-id={175}>
                          Netherlands Antilles
                        </option>
                        <option value="NC" data-select2-id={176}>
                          New Caledonia
                        </option>
                        <option value="NZ" data-select2-id={177}>
                          New Zealand
                        </option>
                        <option value="NI" data-select2-id={178}>
                          Nicaragua
                        </option>
                        <option value="NE" data-select2-id={179}>
                          Niger
                        </option>
                        <option value="NG" data-select2-id={180}>
                          Nigeria
                        </option>
                        <option value="NU" data-select2-id={181}>
                          Niue
                        </option>
                        <option value="NF" data-select2-id={182}>
                          Norfolk Island
                        </option>
                        <option value="KP" data-select2-id={183}>
                          North Korea
                        </option>
                        <option value="NO" data-select2-id={184}>
                          Norway
                        </option>
                        <option value="OM" data-select2-id={185}>
                          Oman
                        </option>
                        <option value="PK" data-select2-id={186}>
                          Pakistan
                        </option>
                        <option value="PS" data-select2-id={187}>
                          Palestinian Territory
                        </option>
                        <option value="PA" data-select2-id={188}>
                          Panama
                        </option>
                        <option value="PG" data-select2-id={189}>
                          Papua New Guinea
                        </option>
                        <option value="PY" data-select2-id={190}>
                          Paraguay
                        </option>
                        <option value="PE" data-select2-id={191}>
                          Peru
                        </option>
                        <option value="PH" data-select2-id={192}>
                          Philippines
                        </option>
                        <option value="PN" data-select2-id={193}>
                          Pitcairn
                        </option>
                        <option value="PL" data-select2-id={194}>
                          Poland
                        </option>
                        <option value="PT" data-select2-id={195}>
                          Portugal
                        </option>
                        <option value="QA" data-select2-id={196}>
                          Qatar
                        </option>
                        <option value="IE" data-select2-id={197}>
                          Republic of Ireland
                        </option>
                        <option value="RE" data-select2-id={198}>
                          Reunion
                        </option>
                        <option value="RO" data-select2-id={199}>
                          Romania
                        </option>
                        <option value="RU" data-select2-id={200}>
                          Russia
                        </option>
                        <option value="RW" data-select2-id={201}>
                          Rwanda
                        </option>
                        <option value="ST" data-select2-id={202}>
                          São Tomé and Príncipe
                        </option>
                        <option value="BL" data-select2-id={203}>
                          Saint Barthélemy
                        </option>
                        <option value="SH" data-select2-id={204}>
                          Saint Helena
                        </option>
                        <option value="KN" data-select2-id={205}>
                          Saint Kitts and Nevis
                        </option>
                        <option value="LC" data-select2-id={206}>
                          Saint Lucia
                        </option>
                        <option value="SX" data-select2-id={207}>
                          Saint Martin (Dutch part)
                        </option>
                        <option value="MF" data-select2-id={208}>
                          Saint Martin (French part)
                        </option>
                        <option value="PM" data-select2-id={209}>
                          Saint Pierre and Miquelon
                        </option>
                        <option value="VC" data-select2-id={210}>
                          Saint Vincent and the Grenadines
                        </option>
                        <option value="SM" data-select2-id={211}>
                          San Marino
                        </option>
                        <option value="SA" data-select2-id={212}>
                          Saudi Arabia
                        </option>
                        <option value="SN" data-select2-id={213}>
                          Senegal
                        </option>
                        <option value="RS" data-select2-id={214}>
                          Serbia
                        </option>
                        <option value="SC" data-select2-id={215}>
                          Seychelles
                        </option>
                        <option value="SL" data-select2-id={216}>
                          Sierra Leone
                        </option>
                        <option value="SG" data-select2-id={217}>
                          Singapore
                        </option>
                        <option value="SK" data-select2-id={218}>
                          Slovakia
                        </option>
                        <option value="SI" data-select2-id={219}>
                          Slovenia
                        </option>
                        <option value="SB" data-select2-id={220}>
                          Solomon Islands
                        </option>
                        <option value="SO" data-select2-id={221}>
                          Somalia
                        </option>
                        <option value="ZA" data-select2-id={222}>
                          South Africa
                        </option>
                        <option value="GS" data-select2-id={223}>
                          South Georgia/Sandwich Islands
                        </option>
                        <option value="KR" data-select2-id={224}>
                          South Korea
                        </option>
                        <option value="SS" data-select2-id={225}>
                          South Sudan
                        </option>
                        <option value="ES" data-select2-id={226}>
                          Spain
                        </option>
                        <option value="LK" data-select2-id={227}>
                          Sri Lanka
                        </option>
                        <option value="SD" data-select2-id={228}>
                          Sudan
                        </option>
                        <option value="SR" data-select2-id={229}>
                          Suriname
                        </option>
                        <option value="SJ" data-select2-id={230}>
                          Svalbard and Jan Mayen
                        </option>
                        <option value="SZ" data-select2-id={231}>
                          Swaziland
                        </option>
                        <option value="SE" data-select2-id={232}>
                          Sweden
                        </option>
                        <option value="CH" data-select2-id={233}>
                          Switzerland
                        </option>
                        <option value="SY" data-select2-id={234}>
                          Syria
                        </option>
                        <option value="TW" data-select2-id={235}>
                          Taiwan
                        </option>
                        <option value="TJ" data-select2-id={236}>
                          Tajikistan
                        </option>
                        <option value="TZ" data-select2-id={237}>
                          Tanzania
                        </option>
                        <option value="TH" data-select2-id={238}>
                          Thailand
                        </option>
                        <option value="TL" data-select2-id={239}>
                          Timor-Leste
                        </option>
                        <option value="TG" data-select2-id={240}>
                          Togo
                        </option>
                        <option value="TK" data-select2-id={241}>
                          Tokelau
                        </option>
                        <option value="TO" data-select2-id={242}>
                          Tonga
                        </option>
                        <option value="TT" data-select2-id={243}>
                          Trinidad and Tobago
                        </option>
                        <option value="TN" data-select2-id={244}>
                          Tunisia
                        </option>
                        <option value="TR" data-select2-id={245}>
                          Turkey
                        </option>
                        <option value="TM" data-select2-id={246}>
                          Turkmenistan
                        </option>
                        <option value="TC" data-select2-id={247}>
                          Turks and Caicos Islands
                        </option>
                        <option value="TV" data-select2-id={248}>
                          Tuvalu
                        </option>
                        <option value="UG" data-select2-id={249}>
                          Uganda
                        </option>
                        <option value="UA" data-select2-id={250}>
                          Ukraine
                        </option>
                        <option value="AE" data-select2-id={251}>
                          United Arab Emirates
                        </option>
                        <option value="GB" data-select2-id={252}>
                          United Kingdom (UK)
                        </option>
                        <option value="US" data-select2-id={253}>
                          USA (US)
                        </option>
                        <option value="UY" data-select2-id={254}>
                          Uruguay
                        </option>
                        <option value="UZ" data-select2-id={255}>
                          Uzbekistan
                        </option>
                        <option value="VU" data-select2-id={256}>
                          Vanuatu
                        </option>
                        <option value="VA" data-select2-id={257}>
                          Vatican
                        </option>
                        <option value="VE" data-select2-id={258}>
                          Venezuela
                        </option>
                        <option value="VN" data-select2-id={259}>
                          Vietnam
                        </option>
                        <option value="WF" data-select2-id={260}>
                          Wallis and Futuna
                        </option>
                        <option value="EH" data-select2-id={261}>
                          Western Sahara
                        </option>
                        <option value="WS" data-select2-id={262}>
                          Western Samoa
                        </option>
                        <option value="YE" data-select2-id={263}>
                          Yemen
                        </option>
                        <option value="ZM" data-select2-id={264}>
                          Zambia
                        </option>
                        <option value="ZW" data-select2-id={265}>
                          Zimbabwe
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-row row">
                  <div className="form-group col-lg-12">
                    <input
                      required="required"
                      placeholder="State / Country"
                      name="name"
                      type="text"
                    />
                  </div>
                  <div className="form-group col-lg-12">
                    <input
                      required="required"
                      placeholder="PostCode / ZIP"
                      name="name"
                      type="text"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="p-3">
              <h4 className="mb-1">Apply Coupon</h4>
              <p className="mb-3">
                <span className="font-lg text-muted">Using A Promo Code?</span>
              </p>
              <form action="#">
                <div className="d-flex justify-content-between">
                  <input
                    className="font-medium mr-1 mb-3 coupon"
                    name="Coupon"
                    placeholder="Enter Your Coupon"
                  />
                  <button className="btn">
                    
                    Apply
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div> */}
            </div>
            <Checkout />
          </div>
        </section>
      </div>

    </div>
  )
}
