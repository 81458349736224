import React, { useEffect, useState, useMemo } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient, { requestData } from "../../utils/HttpClient";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeAllItem } from "../../Redux/reducer/Cart";
import { requestShiprocket } from "../../utils/HttpClient";
import moment from "moment";
import ModalManu from "../../Component/Checkout/Modal";

export default function Index() {
  const dispatch = useDispatch()
  const navigale = useNavigate()
  const [newCartData, setnewCartData] = useState([]);
  const [totalAmount, setTotalAmount] = useState()
  // const [full_name, setFull_name] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phone_no, setPhone_no] = useState("")
  const [country, setCountry] = useState("India")
  const [houseNo, setHouseNo] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [pinCode, setPinCode] = useState("")
  const [paymentType, setPaymentType] = useState("")
  const [email, setEmail] = useState("")
  const [orderNotes, setOrderNots] = useState("")
  const [address, setAddress] = useState("")
  const [coupon_code, setCoupon_code] = useState("")
  const [orderData, setOrderData] = useState("");
  const [weight, setWeight] = useState("");
  const [dimention, setDimention] = useState("");

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  let indiaState = ["Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry"]


  let userData = reactLocalStorage.getObject("userData")
  const cartData = useSelector((state) => state.Cart.cartData);
  console.log("DataRex", cartData);

  let orderId = Math.floor((Math.random() * 100000) + 1);

  const totalCount = useMemo(() => {
    return cartData.reduce((a, b) => parseInt(a) + parseInt(b.product_count), 0);
  }, [cartData]);

  console.log("count", totalCount);

  const totalWeight = useMemo(() => {
    return newCartData.reduce((acc, val) => parseInt(acc) + parseInt(val.product_weight), 0)
  }, [newCartData])

  const orderItem = [];
  cartData?.map(item => {
    let obj = {
      name: item.product_name,
      selling_price: item.total_price,
      sku: "789458",
      units: item.product_count
    };
    orderItem.push(obj)
  })

  const items = [];
  cartData?.map(item => {
    items.push(item.product_name)
  })
  console.log("ProdItems", items.toString());

  const PlaceOrder = async () => {
    if (firstName !== "", lastName !== "", paymentType !== "", email !== "", phone_no !== "", city !== "", pinCode !== "", state !== "") {
      if (address !== "", houseNo !== "") {
        let emailRegex = /^([\-\.0-9a-zA-Z]+)@([\-\.0-9a-zA-Z]+)\.([a-zA-Z]){1,7}$/;
        if (emailRegex.test(email)) {
          if(phone_no.length === 10){
            let data = {
              user_id: userData.id,
              full_name: `${firstName} ${lastName}`,
              phone_no: phone_no,
              country: country,
              house_no: houseNo,
              address: address,
              state: state,
              city: city,
              pincode: pinCode,
              payment_type: paymentType,
              email: email,
              order_notes: orderNotes,
              coupon_code: coupon_code
            }
            console.log(data);
            // return false
            let result = await HttpClient.requestData("user/user_order_product", "POST", data)
            if (result && result.status) {
              console.log("userOrderProduct", result);
              createOrder(result.invoice_id)
              dispatch(removeAllItem())
              setTimeout(() => {
                navigale("/")
              }, 10000);
            } else {
              toast.error("Order Not Placed");
            }
          }
          else {
            toast.error("Phone no should be 10 numbers!")
          }
        }
        else {
          toast.error("Enter a valid email!")
        }

      }
      else {
        toast.error("Address and Housename is required");
      }
    }
    else {
      toast.error("All field required");
    }
  }

  const createOrder = async (invoice) => {
    const data = {
      order_id: invoice ? invoice : orderId,
      order_date: moment(new Date()).format('YYYY-MM-DD'),
      pickup_location: "Primary",
      billing_address: `${houseNo} ${address}`,
      billing_last_name: lastName,
      billing_customer_name: firstName,
      billing_city: city,
      billing_pincode: pinCode,
      billing_state: state,
      billing_country: country,
      billing_email: email,
      billing_phone: phone_no,
      shipping_is_billing: true,
      order_items: orderItem,
      name: items.toString(),
      sku: "456770",
      units: totalCount,
      selling_price: totalAmount,
      payment_method: paymentType === 'COD' ? 'COD' : 'Prepaid',
      sub_total: totalAmount,
      length: 120,
      breadth: 80,
      height: 40,
      weight: totalWeight
    }
    const result = await requestShiprocket('orders/create/adhoc', 'POST', data)
    console.log("List", result);
    if (result.status_code === 1) {
      onOpenModal()
      setOrderData({
        order_id: result.order_id,
        shipment_id: result.shipment_id
      })
      toast.success("Order Placed SuccessFully!")
      let data = {
        invoice_id: invoice,
        order_id: result.order_id,
        shipment_id: result.shipment_id
      }
      let res = await requestData('user/order_update', 'POST', data)
      console.log("DataAdd", res);
    }
    else {
      toast.error("Shiprocket Server busy! Try again later")
      setTimeout(() => {
        navigale("/")
      }, 2000);
    }
  }

  useEffect(() => {
    fatchCart();
  }, []);

  //   console.log(newCartData);
  const fatchCart = async (e) => {
    let data = reactLocalStorage.getObject("userData");
    let userID = {
      user_id: data.id,
    };

    let result = await HttpClient.requestData(
      "user/product_fetch_cart",
      "POST",
      userID
    );
    console.log("result", result);
    setnewCartData(result.data);
    setTotalAmount(result.subtotal)
    setDimention(result.product_dimention)
  };

  return (
    <>
      {console.log("data", newCartData)}
      <ToastContainer />
      <section className="main checkout">
        <div className="container">
          <form method="post">
            <div className="row">
              <div className="col-lg-7 mb-6 mb-lg-0 check-detail">
                <h3 className="title text-left mt-3 mb-6">Billing Details</h3>

                <label>First Name*</label>
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  className="form-control"
                  name="full_name"
                  required
                />

                <label>Last Name*</label>
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  className="form-control"
                  name="full_name"
                  required
                />


                <label>Street Address*</label>
                <input
                  value={houseNo}
                  onChange={(e) => setHouseNo(e.target.value)}
                  type="text"
                  className="form-control"
                  required
                  placeholder="House number"
                  name="house_no"
                />
                <input
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                  className="form-control"
                  required
                  placeholder="Apartment, suite, unit, etc."
                  name="address"
                />
                <div className="row">
                  <div className="col-md-6">
                    <label>State*</label>

                    <select
                      onChange={(e) => setState(e.target.value)}
                      className="form-control"
                      name="state"
                      id="state"
                    // onchange="get_city()"
                    >
                      <option value="">-Select-State</option>
                      {indiaState.map((item, index) => {
                        return (
                          <option key={index} value={item}>{item}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label>City*</label>
                    <input
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      type="text"
                      className="form-control"
                      required
                      placeholder="Enter your City"
                      name="city"
                    />

                    {/* <select className="form-control" onChange={(e) => setCity(e.target.value)} name="city" id="city">
                      <option value="">-Select-</option>
                      <option value="Kolkata">Kolkata</option>

                    </select> */}

                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>Postcode / ZIP*</label>
                    <input
                      value={pinCode}
                      onChange={(e) => setPinCode(e.target.value)}
                      type="text"
                      className="form-control"
                      name="zip"
                      required=""
                      oninput="this.value=this.value.replace(/[^0-9 ]/g,'');"
                      maxLength={8}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Phone*</label>
                    <input
                      value={phone_no}
                      onChange={(e) => setPhone_no(e.target.value.slice(0, 10))}
                      type="number"
                      className="form-control"
                      name="phone"
                      required=""
                      oninput="this.value=this.value.replace(/[^0-9 ]/g,'');"
                      maxLength={10}
                      pattern="(7|8|9)\d{9}"
                    />
                  </div>
                </div>
                <label>Email Address*</label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  className="form-control"
                  name="email"
                  required=""
                />

                <h2 className="title pt-2 mb-6">Additional Information</h2>
                <label>Order Notes (optional)</label>
                <textarea
                  value={orderNotes}
                  onChange={(e) => setOrderNots(e.target.value)}
                  name="order_note"
                  className="form-control mb-0"
                  cols={30}
                  rows={5}
                  placeholder="Write something here..."
                  defaultValue={""}
                />
              </div>

              <aside className="col-lg-5 sticky-sidebar-wrapper  pl-lg-6">
                <div
                  className="sticky-sidebar"
                  data-sticky-options="{'bottom': 50}"
                >
                  <div className="summary pt-5">
                    <h3 className="title">Your Order</h3>
                    <table className="order-sidebar">
                      <thead>
                        <tr>
                          <th>Product</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newCartData.map((value, index) => (
                          <tr>
                            <td className="product-name">
                              {" "}
                              {value.product_name} ₹ {value.actual_price}
                              <span className="product-quantity">
                                × {value.product_count}
                              </span>
                            </td>
                            <td className="product-total text-body">
                              ₹{value.total_price}
                            </td>
                          </tr>
                        ))}
                        {/* <tr>
                                                    <td className="product-name">
                                                        {" "}
                                                        Banana From Indonesia
                                                        <span className="product-quantity">×1</span>
                                                    </td>
                                                    <td className="product-total text-body">$16</td>
                                                </tr> */}

                        <tr className="summary-subtotal">
                          <td>
                            <h4 className="summary-subtitle">Subtotal</h4>
                          </td>
                          <td className="summary-subtotal-price">₹{totalAmount} </td>
                        </tr>

                        <tr className="summary-total">
                          <td>
                            <h4 className="summary-subtitle">Total</h4>
                          </td>
                          <td>
                            <p className="summary-total-price ls-s">₹{totalAmount}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* <div className="col-lg-12">
                      <div className="p-3 coupon-apply">
                        <h4 className="mb-1">Apply Coupon</h4>
                        <p className="mb-3">
                          <span className="font-lg text-muted">
                            Using A Promo Code?
                          </span>
                        </p>
                        <form action="#">
                          <div className="d-flex justify-content-between">
                            <input
                              className="font-medium mr-1 mb-3 coupon"
                              name="Coupon"
                              placeholder="Enter Your Coupon"
                            />
                            <button className="btn">Apply</button>
                          </div>
                        </form>
                      </div>
                    </div> */}
                    <div className="payment accordion radio-type pb-5">
                      <h4 className="summary-subtitle ls-m pb-3">
                        Payment Methods
                      </h4>
                      <div className="card">
                        <div className="card-header">
                          <input
                            type="radio"
                            name="paym"
                            onChange={() => setPaymentType("Check payments")}
                            defaultValue="check payments"
                          />
                          <label className="text-dark">Check payments</label>
                        </div>
                        <div
                          id="collapse1"
                          className="expanded"
                          style={{ display: "block" }}
                        >
                          <div className="card-body">
                            Please send a check to Store Name, Store Street,
                            Store Town, Store State / County, Store Postcode.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          {/*<a href="#collapse2" class="expand">Cash*/}
                          {/*    on delivery</a>*/}
                          <input
                            type="radio"
                            name="paym"
                            onChange={() => setPaymentType("COD")}
                            defaultValue="cash on delivery"
                          />
                          <label className="text-dark">Cash on delivery</label>
                        </div>
                        <div id="collapse2" className="collapsed">
                          <div className="card-body">
                            Pay with cash upon delivery.
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <button
                    //   type="submit"
                      name="place_order"
                      className="btn btn-dim btn-block mt-6"
                    >
                      Place Order
                    </button> */}
                    <h1 onClick={PlaceOrder} className="btn btn-dim btn-block mt-6">Place Order</h1>
                  </div>
                </div>
              </aside>
            </div>
          </form>
        </div>
      </section>
      <ModalManu
        style={{background: "#fff"}} 
        open={open}
        onCloseModal={onCloseModal}
        orderData={orderData}
      />
    </>
  );
}
