import React, { useState, useEffect } from "react";
import h from "../../images/1984.jpg";
import c from "../../images/download.jpg";
import carthome from "../../images/cart-green.png";
import HttpClient from "../../utils/HttpClient";
import { useSelector } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { setCartData } from "../../Redux/reducer/Cart";
import { useDispatch } from "react-redux";
import { setShow } from "../../Redux/reducer/LoginShow";
import { Navigate, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeInanimation from "../../Component/FadeInSection/FadeInanimation";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation  } from 'swiper/modules';
export default function ProductByCategory(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartData } = useSelector((state) => state.Cart);
  const [productCount, setProductCount] = useState(1);

  const [productByCat, setProductByCat] = useState([]);

  useEffect(() => {
    fetchProductByCat();
  }, []);

  const fetchProductByCat = async () => {
    let dataSend = {
      status: "favourite",
    };
    let res = await HttpClient.requestData(
      "frontend/fetchAllProduct",
      "POST",
      dataSend
    );
    console.log("all", res);
    if (res && res.status) {
      setProductByCat(res.data);
    }
  };

  const AddCart = async (id) => {
    // console.log("bal")
    let login = reactLocalStorage.get("login_status");
    let user = reactLocalStorage.getObject("userData");
    if (login) {
      let dataSend = {
        user_id: user.id,
        product_id: id,
        product_count: productCount,
      };
      console.log("data", dataSend);
      let result = await HttpClient.requestData(
        "user/product_add_to_cart",
        "POST",
        dataSend
      );
      // console.log(result);
      if (result && result.status) {
        console.log("result.data", result.data);
        dispatch(setCartData(result.data));
        // alert("Product added in cart!!!");
        toast("Product added to cart!!!")
        // navigate("/cart");
      } else {
        // console.log("error", result)
        // alert(result.error);
        toast.warn(result.error)
      }
    } else {
      // alert("Please Login first")
      dispatch(setShow());
    }
  };
  // const loop = [1, 2, 3, 4, 5];
  return (
    <>
      <ToastContainer />
      <div>
        <section className="bookwali-product-page-part clearfix">
          <div className="container-fluid">

            <div class="sectionhead">
              <h4>{props.name}</h4>
            </div>
            <FadeInanimation fadeUp>
              <div className="rhejuzesewar">
                <Swiper
                  slidesPerView={5}
                  spaceBetween={10}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    300: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    400: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    690:{
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    700: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    991: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                    1366: {
                      slidesPerView: 5,
                      spaceBetween: 30,
                    },
                  }}
                  className="myHotDealsSwiper"
                >
                  {productByCat.map((item, index) => {
                    return (
                      <SwiperSlide>
                        <div key={index} className="bookwali-product-box">
                          <div className="bookwali-productbg">
                            <div className="bookwali-product-img">
                              {/* <div className="bookwali-product-badges">
                        <span className="hot">Hot</span>
                      </div> */}
                              <Link to={`/shopdetails/${item.product_id}`}>
                                <img
                                  src={`https://api.bookswali.com/public/images/${item.main_img}`}

                                />
                              </Link>
                            </div>
                          </div>
                          <div className="bookwali-product-content">

                            <h2>
                              <Link to={`/shopdetails/${item.product_id}`}>{item.Product_title}</Link>
                            </h2>

                            {item.language && item.publishers !== null ? (
                              <h4>
                                <Link to={`/shopdetails/${item.product_id}`}>
                                  {item.language}, {item.publishers}
                                </Link>
                              </h4>
                            ) : <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut mi eget est imperdiet finibus non a est</h4>}

                            <div className="product-rate-box">
                              <ul className="product-rate-list">
                                <li className="float-left">
                                  <a href="#">
                                    <i className="fa fa-star" aria-hidden="true" />
                                  </a>
                                </li>
                                <li className="float-left">
                                  <a href="#">
                                    <i className="fa fa-star" aria-hidden="true" />
                                  </a>
                                </li>
                                <li className="float-left">
                                  <a href="#">
                                    <i className="fa fa-star" aria-hidden="true" />
                                  </a>
                                </li>
                                <li className="float-left">
                                  <a href="#">
                                    <i className="fa fa-star" aria-hidden="true" />
                                  </a>
                                </li>
                                <li className="float-left">
                                  <a href="#">
                                    <i className="fa fa-star" aria-hidden="true" />
                                  </a>
                                </li>
                                <li className="float-left">
                                  <a href="#">
                                    <i
                                      className="fa fa-star-half-o"
                                      aria-hidden="true"
                                    />
                                  </a>
                                </li>
                              </ul>
                              <span
                                className="font-small"
                                style={{ color: "#B6B6B6", fontSize: 12 }}
                              >
                                (4.0)
                              </span>
                            </div>
                            <span
                              className="font-small byGap"
                              style={{ color: "#B6B6B6", fontSize: 12 }}
                            >By
                              {item.author !== null ? (
                                <Link to={`/shopdetails/${item.product_id}`}> {item.author}</Link>
                              ) : null}
                            </span>
                          </div>
                          {/* <span
                    className="font-small"
                    style={{ color: "#B6B6B6", fontSize: 12 }}
                  >
                    By<a href="#">{item.author}</a>
                  </span> */}
                          <div className="product-card-bottom">
                            <div className="product-price">
                              <span className="mr-2">₹{item.selling_price}</span>
                              <del className="old-price">₹{item.price}</del>
                            </div>
                            <div className="product-add-cart">
                              <a
                                className="product-add-btn"
                                onClick={() => AddCart(item.product_id)}
                              >
                                {/* <img
                          src={carthome}
                          alt="Cart"
                          className="img-fluid"
                          // onClick={}
                        /> */}
                                <i class="fa-solid fa-cart-plus"></i>
                                Add{" "}
                              </a>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </FadeInanimation>
          </div>
        </section>
      </div>
    </>
  );
}
